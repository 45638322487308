import React, { useState, useEffect } from 'react';
import { Plus, Search as SearchIcon, ChevronLeft, Heart, Trash2, AlertTriangle } from "lucide-react";
import { useNavigate } from 'react-router-dom';
import { FaPlay, FaPause } from "react-icons/fa";
import { collection, addDoc, updateDoc, getDocs, doc, deleteDoc, Timestamp, query, where } from 'firebase/firestore';
import { db } from './firebaseConfig';
import DOMPurify from 'dompurify';
import { Album, Song, User, DeleteConfirmationModalProps, AlbumPageProps } from '../types';
import AddInstrumentalModal from './AddInstrumentalModal';

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
    albumName,
    instrumentalCount
}) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-gray-800 rounded-lg p-6 w-full max-w-md">
                <div className="flex items-center mb-4 text-yellow-500">
                    <AlertTriangle size={24} className="mr-2" />
                    <h2 className="text-xl font-bold">Confirmer la suppression</h2>
                </div>

                <p className="text-white mb-4">
                    Êtes-vous sûr de vouloir supprimer l'album "{albumName}" ?
                    {instrumentalCount > 0 && (
                        <span className="block mt-2 text-red-500">
                            Cet album contient {instrumentalCount} instrumentale{instrumentalCount > 1 ? 's' : ''}.
                            La suppression est définitive !
                        </span>
                    )}
                </p>

                <div className="flex justify-end space-x-2">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-700 rounded hover:bg-gray-600"
                    >
                        Annuler
                    </button>
                    <button
                        onClick={onConfirm}
                        className="px-4 py-2 bg-red-500 rounded hover:bg-red-600"
                    >
                        Supprimer
                    </button>
                </div>
            </div>
        </div>
    );
};

const AlbumPage: React.FC<AlbumPageProps> = ({
    user,
    songs,
    onAddInstrumental,
    currentSong,
    contextSetCurrentSong,
    contextTogglePlay,
    playlists,
    setIsVideoPlayerOpen,
    addSongToPlaylist,
    createPlaylist
}) => {
    const [albums, setAlbums] = useState<Album[]>([]);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isAddInstrumentalModalOpen, setIsAddInstrumentalModalOpen] = useState(false);
    const [newAlbumName, setNewAlbumName] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [isPlaying, setIsPlaying] = useState(false);
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [albumToDelete, setAlbumToDelete] = useState<Album | null>(null);
    const [isAddPlaylistModalOpen, setIsAddPlaylistModalOpen] = useState(false);

    const handleDeleteAlbum = async (album: Album) => {
        setAlbumToDelete(album);
        setDeleteModalOpen(true);
    };

    const handleAddPlaylist = async (name: string): Promise<void> => {
        try {
            const newPlaylist = await createPlaylist(name);
            setIsAddPlaylistModalOpen(false);
        } catch (error) {
            console.error("Erreur lors de la création de la playlist:", error);
        }
    };

    const confirmDeleteAlbum = async () => {
        if (!albumToDelete) return;

        try {
            // 1. Récupérer toutes les instrumentales de l'album
            const instrumentalsQuery = query(
                collection(db, "songs"),
                where("tag", "==", albumToDelete.name)
            );
            const instrumentalSnapshot = await getDocs(instrumentalsQuery);

            // 2. Supprimer toutes les instrumentales
            const deletePromises = instrumentalSnapshot.docs.map(doc =>
                deleteDoc(doc.ref)
            );
            await Promise.all(deletePromises);

            // 3. Supprimer l'album
            await deleteDoc(doc(db, "albums", albumToDelete.id));

            // 4. Mettre à jour l'état local
            setAlbums(prev => prev.filter(album => album.id !== albumToDelete.id));

            // 5. Fermer la modale
            setDeleteModalOpen(false);
            setAlbumToDelete(null);
        } catch (error) {
            console.error("Erreur lors de la suppression de l'album:", error);
            alert("Une erreur est survenue lors de la suppression de l'album.");
        }
    };

    // Détecter si l'appareil est mobile
    useEffect(() => {
        const checkDevice = () => {
            setIsMobile(window.innerWidth <= 1024); // 1024px est généralement la limite pour les tablettes
        };

        checkDevice();
        window.addEventListener('resize', checkDevice);

        return () => {
            window.removeEventListener('resize', checkDevice);
        };
    }, []);

    const handlePlayClick = async (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>, song: Song) => {
        e.preventDefault();
        e.stopPropagation();

        if (currentSong?.id === song.id) {
            contextTogglePlay();
        } else {
            await contextSetCurrentSong(song);
        }
        setIsVideoPlayerOpen(true); // On utilise la prop passée par AppContent au lieu de l'état local
    };

    useEffect(() => {
        const fetchAlbums = async () => {
            try {
                const albumsSnapshot = await getDocs(collection(db, "albums"));
                const albumsData = albumsSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    createdAt: doc.data().createdAt || Timestamp.now()
                })) as Album[];
                setAlbums(albumsData);
            } catch (error) {
                console.error("Erreur lors du chargement des albums:", error);
            }
        };

        fetchAlbums();
    }, []);

    const handleCreateAlbum = async () => {
        if (!newAlbumName.trim()) return;

        try {
            const now = Timestamp.now();
            const albumData = {
                name: newAlbumName.trim(),
                instrumentals: [],
                createdAt: now,
                imageUrl: null  // Ajouté ici
            };

            const docRef = await addDoc(collection(db, "albums"), albumData);

            const newAlbum: Album = {
                id: docRef.id,
                name: newAlbumName.trim(),
                instrumentals: [],
                createdAt: now,
            };

            setAlbums(prev => [...prev, newAlbum]);
            setNewAlbumName('');
            setIsCreateModalOpen(false);
        } catch (error) {
            console.error("Erreur lors de la création de l'album:", error);
        }
    };

    // Filtrer les albums en fonction du terme de recherche
    const filteredAlbums = albums
        .filter(album => album.name.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds); // Trier par date de création

    // Obtenir les instrumentales pour un album spécifique
    const getAlbumInstrumentals = (albumName: string) => {
        return songs.filter(song => song.tag === albumName);
    };

    return (
        <div className="flex flex-col min-h-screen bg-gradient-to-b from-gray-900 to-black text-white p-4">
            {/* Header */}
            <div className="flex items-center justify-between mb-6">
                <div className="flex items-center">
                    <button onClick={() => navigate('/')} className="mr-4">
                        <ChevronLeft size={24} />
                    </button>
                    <h1 className="text-3xl font-bold">Collections</h1>
                </div>
                <div className="flex space-x-2">
                    <button
                        onClick={() => setIsCreateModalOpen(true)}
                        className="p-2 bg-gray-800 rounded-full hover:bg-gray-700"
                        title="Créer une collection"
                    >
                        <Plus size={24} />
                    </button>
                    <button
                        onClick={() => setIsAddInstrumentalModalOpen(true)}
                        className="p-2 bg-gray-800 rounded-full hover:bg-gray-700"
                        title="Ajouter une instrumentale"
                    >
                        <Plus size={24} />
                    </button>
                </div>
            </div>

            {/* Barre de recherche */}
            <div className="mb-6">
                <input
                    type="text"
                    placeholder="Rechercher une collection..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full p-3 bg-gray-800 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
            </div>

            {/* Liste des albums et leurs instrumentales */}
            <div className="space-y-8">
                {filteredAlbums.map(album => {
                    const albumInstrumentals = getAlbumInstrumentals(album.name);

                    return (
                        <div key={album.id} className="bg-gray-800 rounded-lg ">
                            <div className="flex justify-between items-center p-3">
                                <h2 className="text-xl font-bold">{album.name}</h2>
                                <button
                                    onClick={() => handleDeleteAlbum(album)}
                                    className="p-2 text-red-500 hover:text-red-600 rounded-full hover:bg-gray-700 transition-colors"
                                    title="Supprimer l'album"
                                >
                                    <Trash2 size={20} />
                                </button>
                            </div>

                            <div
                                className="flex space-x-4 overflow-x-auto scrollbar-hide p-2"
                                style={{ scrollBehavior: 'smooth' }}
                            >
                                {albumInstrumentals.map((song) => (
                                    <div key={song.id} className="flex-shrink-0 w-40">
                                        <div
                                            className="relative cursor-pointer group"
                                            onClick={(e) => handlePlayClick(e, song)}
                                        >
                                            <img
                                                src={song.thumbnailUrl || "/api/placeholder/160/160"}
                                                alt={song.name}
                                                className={`w-full h-40 object-cover rounded-lg album-cover ${currentSong?.id === song.id && isPlaying ? 'spinning' : ''}`}
                                            />
                                            {!isMobile && song.videoUrl && currentSong?.id === song.id && isPlaying && (
                                                <video
                                                    src={song.videoUrl}
                                                    className="absolute inset-0 w-full h-full object-cover rounded-lg"
                                                    autoPlay
                                                    loop
                                                    muted
                                                    playsInline
                                                />
                                            )}
                                            <button
                                                type="button"
                                                onClick={(e) => handlePlayClick(e, song)}
                                                className="absolute bottom-2 right-2 bg-green-500 text-white rounded-full p-2 hover:bg-green-600 z-10"
                                            >
                                                {currentSong?.id === song.id && isPlaying ? (
                                                    <FaPause size={15} />
                                                ) : (
                                                    <FaPlay size={15} />
                                                )}
                                            </button>
                                        </div>
                                        <div className="mt-2">
                                            <p className="font-semibold text-sm truncate">{song.name}</p>
                                            <p className="text-xs text-gray-400 truncate">{song.artist}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {albumInstrumentals.length === 0 && (
                                <div className="text-center py-8 text-gray-400">
                                    Aucune instrumentale dans cet Collection
                                </div>
                            )}
                        </div>
                    );
                })}

                {filteredAlbums.length === 0 && (
                    <div className="text-center py-8 text-gray-400">
                        Aucune Collection trouvé
                    </div>
                )}
            </div>

            {/* Modal de création d'album */}
            {isCreateModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                    <div className="bg-gray-800 rounded-lg p-6 w-full max-w-md">
                        <h2 className="text-xl font-bold mb-4">Créer un album</h2>
                        <input
                            type="text"
                            value={DOMPurify.sanitize(newAlbumName)}
                            onChange={(e) => setNewAlbumName(DOMPurify.sanitize(e.target.value))}
                            placeholder="Nom de l'album"
                            className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
                            maxLength={25}
                        />
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={() => setIsCreateModalOpen(false)}
                                className="px-4 py-2 bg-gray-700 rounded hover:bg-gray-600"
                            >
                                Annuler
                            </button>
                            <button
                                onClick={handleCreateAlbum}
                                className="px-4 py-2 bg-green-500 rounded hover:bg-green-600"
                            >
                                Créer
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal d'ajout d'instrumentale */}
            {isAddInstrumentalModalOpen && (
                <AddInstrumentalModal
                    isOpen={isAddInstrumentalModalOpen}
                    onClose={() => setIsAddInstrumentalModalOpen(false)}
                    onAddInstrumental={onAddInstrumental}
                    albums={albums}
                />
            )}

            {/* Modal de confirmation de suppression */}
            <DeleteConfirmationModal
                isOpen={deleteModalOpen}
                onClose={() => {
                    setDeleteModalOpen(false);
                    setAlbumToDelete(null);
                }}
                onConfirm={confirmDeleteAlbum}
                albumName={albumToDelete?.name || ''}
                instrumentalCount={albumToDelete ? getAlbumInstrumentals(albumToDelete.name).length : 0}
            />
        </div>


    );
};

export default AlbumPage;