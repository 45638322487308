import React, { useEffect, useRef, useState } from 'react';
import { Loader } from 'lucide-react';
import { usePlayer } from '../contexts/PlayerContext';
import VideoPlayerBar from './VideoPlayerBar';
import { Playlist, User, VideoPlayerProps } from '../types';

const VideoPlayer: React.FC<VideoPlayerProps> = ({
    onClose,
    playlists,
    user,
    addSongToPlaylist,
    createPlaylist
}) => {
    const { currentSong, isLiked, toggleLikeSong, isPlaying, togglePlay } = usePlayer();
    const [isLoading, setIsLoading] = useState(true);
    const videoRef = useRef<HTMLVideoElement>(null);

    // Gestion du chargement initial de la vidéo
    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            const handleLoadStart = () => setIsLoading(true);
            const handleLoadedData = () => {
                setIsLoading(false);
                if (isPlaying) {
                    video.play().catch(console.error);
                }
            };

            video.addEventListener('loadstart', handleLoadStart);
            video.addEventListener('loadeddata', handleLoadedData);

            return () => {
                video.removeEventListener('loadstart', handleLoadStart);
                video.removeEventListener('loadeddata', handleLoadedData);
            };
        }
    }, [isPlaying]);

    // Synchroniser la lecture/pause de la vidéo avec l'état de lecture
    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            if (isPlaying) {
                video.play().catch(console.error);
            } else {
                video.pause();
            }
        }
    }, [isPlaying]);

    // S'assurer que la vidéo est arrêtée lors du démontage du composant
    useEffect(() => {
        return () => {
            const video = videoRef.current;
            if (video) {
                video.pause();
                video.currentTime = 0;
            }
        };
    }, []);

    const handleCreatePlaylist = async (name: string): Promise<void> => {
        try {
            await createPlaylist(name);
        } catch (error) {
            console.error("Erreur lors de la création de la playlist:", error);
        }
    };

    const handleLike = () => {
        if (currentSong && currentSong.id) {
            toggleLikeSong(currentSong.id);
        }
    };

    if (!currentSong || !currentSong.videoUrl) return null;

    return (
        <div className="fixed inset-0 bg-black sm:bg-black/90 z-50 sm:flex sm:items-center sm:justify-center">
            {/* Container avec padding-bottom pour la barre de navigation */}
            <div className="relative w-full h-[calc(100%-3.5rem)] sm:w-[90%] sm:h-[90%] md:w-[80%] md:h-[80%] lg:w-[70%] lg:h-[70%] xl:w-[60%] xl:h-[60%] sm:aspect-video">
                {/* Vidéo */}
                <video
                    ref={videoRef}
                    src={currentSong.videoUrl}
                    className="w-full h-full object-cover sm:object-contain sm:rounded-lg sm:h-[90%]"
                    playsInline
                    loop
                    muted
                />

                {/* Loader */}
                {isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center bg-black/50 sm:rounded-lg">
                        <Loader className="animate-spin text-white" size={48} />
                    </div>
                )}

                {/* Barre de contrôle positionnée au-dessus de la barre de navigation */}
                <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-black/80 to-transparent">
                    <div className="pb-14 sm:pb-0"> {/* Ajout du padding-bottom sur mobile */}
                        <VideoPlayerBar
                            onClose={onClose}
                            onLike={handleLike}
                            isLiked={isLiked}
                            playlists={playlists}
                            user={user}
                            addSongToPlaylist={addSongToPlaylist}
                            createPlaylist={handleCreatePlaylist}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoPlayer;