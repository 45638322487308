import React, { useState, useRef } from 'react';
import { X, Plus, Music } from "lucide-react";
import DOMPurify from 'dompurify';
import { Album } from '../types';

interface AddInstrumentalModalProps {
    isOpen: boolean;
    onClose: () => void;
    onAddInstrumental: (
        name: string,
        artist: string,
        audioFile: File,
        imageFile: File | null,
        duration: number,
        videoFile: File | null,
        tag: string
    ) => Promise<void>;
    albums: Album[];
}

const AddInstrumentalModal: React.FC<AddInstrumentalModalProps> = ({
    isOpen,
    onClose,
    onAddInstrumental,
    albums
}) => {
    const [name, setName] = useState('');
    const [artist, setArtist] = useState('');
    const [tag, setTag] = useState('');
    const [audioFile, setAudioFile] = useState<File | null>(null);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [videoFile, setVideoFile] = useState<File | null>(null);
    const [duration, setDuration] = useState(0);
    const [error, setError] = useState<string | null>(null);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleAudioFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setAudioFile(file);

            const audio = new Audio(URL.createObjectURL(file));
            audio.onloadedmetadata = () => {
                setDuration(Math.round(audio.duration));
                const fileName = file.name.replace(/\.[^/.]+$/, "");
                const parts = fileName.split('-');
                if (parts.length >= 2) {
                    setArtist(DOMPurify.sanitize(parts[0].trim()));
                    setName(DOMPurify.sanitize(parts.slice(1).join('-').trim()));
                } else {
                    setName(DOMPurify.sanitize(fileName));
                }
            };
        }
    };

    const handleTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = DOMPurify.sanitize(e.target.value);
        setTag(value);

        // Filtrer les suggestions d'albums basées sur la saisie
        if (value) {
            const filtered = albums
                .map(album => album.name)
                .filter(name => name.toLowerCase().includes(value.toLowerCase()));
            setSuggestions(filtered);
        } else {
            setSuggestions([]);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!audioFile || !name || !artist || !tag) {
            setError("Veuillez remplir tous les champs requis");
            return;
        }

        try {
            setIsLoading(true);
            await onAddInstrumental(name, artist, audioFile, imageFile, duration, videoFile, tag);
            onClose();
        } catch (error) {
            setError("Une erreur est survenue lors de l'ajout de l'instrumentale");
        } finally {
            setIsLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-gray-800 rounded-lg p-6 w-full max-w-md">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Ajouter une instrumentale</h2>
                    <button onClick={onClose}>
                        <X size={24} />
                    </button>
                </div>

                {error && <p className="text-red-500 mb-4">{error}</p>}

                <form onSubmit={handleSubmit}>
                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium mb-1">Fichier audio</label>
                            <input
                                type="file"
                                accept="audio/*"
                                onChange={handleAudioFileChange}
                                className="w-full p-2 bg-gray-700 rounded"
                                required
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">Image de couverture</label>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => e.target.files && setImageFile(e.target.files[0])}
                                className="w-full p-2 bg-gray-700 rounded"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">Fichier vidéo (optionnel)</label>
                            <input
                                type="file"
                                accept="video/*"
                                onChange={(e) => e.target.files && setVideoFile(e.target.files[0])}
                                className="w-full p-2 bg-gray-700 rounded"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">Nom</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(DOMPurify.sanitize(e.target.value))}
                                className="w-full p-2 bg-gray-700 rounded"
                                required
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">Artiste</label>
                            <input
                                type="text"
                                value={artist}
                                onChange={(e) => setArtist(DOMPurify.sanitize(e.target.value))}
                                className="w-full p-2 bg-gray-700 rounded"
                                required
                            />
                        </div>

                        <div className="relative">
                            <label className="block text-sm font-medium mb-1">Tag Album</label>
                            <input
                                type="text"
                                value={tag}
                                onChange={handleTagChange}
                                className="w-full p-2 bg-gray-700 rounded"
                                placeholder="Entrez un tag correspondant à un album"
                                required
                            />
                            {suggestions.length > 0 && (
                                <div className="absolute w-full bg-gray-700 mt-1 rounded-lg shadow-lg max-h-40 overflow-y-auto">
                                    {suggestions.map((suggestion, index) => (
                                        <div
                                            key={index}
                                            className="p-2 hover:bg-gray-600 cursor-pointer"
                                            onClick={() => {
                                                setTag(suggestion);
                                                setSuggestions([]);
                                            }}
                                        >
                                            {suggestion}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex justify-end space-x-2 mt-6">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-700 rounded hover:bg-gray-600"
                        >
                            Annuler
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-green-500 rounded hover:bg-green-600 disabled:bg-gray-500"
                            disabled={isLoading}
                        >
                            {isLoading ? "Ajout en cours..." : "Ajouter"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddInstrumentalModal;