import React, { useState, useRef } from 'react';
import { Play, Pause, SkipBack, SkipForward, Volume, Volume1, Volume2, VolumeX } from 'lucide-react';
import { Song, MobilePlayerBarProps } from '../types';
import { usePlayer } from '../contexts/PlayerContext';
import { syncVideoWithAudioState } from '../utils/playerUtils';

const MobilePlayerBar: React.FC<MobilePlayerBarProps> = ({
    currentSong,
    isPlaying,
    togglePlay,
    setIsVideoPlayerOpen,
    isVideoPlayerOpen
}) => {
    const { volume, setVolume, currentTime, duration } = usePlayer();
    const volumeBeforeChange = useRef<number>(volume);

    if (!currentSong) return null;
    if (!currentSong || isVideoPlayerOpen) return null;

    const progress = duration > 0 ? (currentTime / duration) * 100 : 0;

    const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVolume(parseFloat(e.target.value));
    };

    const handleVolumeIconClick = () => {
        if (volume === 0) {
            setVolume(volumeBeforeChange.current || 0.5);
        } else {
            volumeBeforeChange.current = volume;
            setVolume(0);
        }
    };

    const renderVolumeIcon = () => {
        if (volume === 0) {
            return <VolumeX size={20} />;
        } else if (volume < 0.3) {
            return <Volume size={20} />;
        } else if (volume < 0.7) {
            return <Volume1 size={20} />;
        } else {
            return <Volume2 size={20} />;
        }
    };

    const handleTogglePlay = () => {
        togglePlay();
        syncVideoWithAudioState(!isPlaying);
    };

    return (
        <div
            className="bg-gradient-to-b from-gray-900 to-black border-t border-gray-800"
        >
            {/* Barre de progression en haut */}
            <div className="w-full bg-gray-700 h-1">
                <div
                    className="bg-white h-1"
                    style={{ width: `${progress}%` }}
                />
            </div>
            <div className="px-4 py-2" style={{ minHeight: '64px' }}>
                <div className="flex items-center justify-between">
                    {/* Informations de la chanson */}
                    <div
                        className="flex items-center flex-1 cursor-pointer"
                    >
                        <img
                            src={currentSong.thumbnailUrl || "/api/placeholder/48/48"}
                            alt={currentSong.name}
                            onClick={() => setIsVideoPlayerOpen(true)}
                            className={`w-12 h-12 rounded-lg object-cover ${isPlaying ? 'spinning' : ''}`}
                        />
                        <div className="ml-3 overflow-hidden">
                            <p className="text-sm font-medium text-white truncate">
                                {currentSong.name}
                            </p>
                            <p className="text-xs text-gray-400 truncate">
                                {currentSong.artist}
                            </p>
                        </div>
                    </div>

                    {/* Contrôles de lecture */}
                    <div className="flex items-center space-x-4">
                        {/* Contrôle de volume compact */}
                        <div className="flex items-center space-x-1 group">
                            <button
                                type="button"
                                onClick={handleVolumeIconClick}
                                className="hover:text-white text-gray-400 transition-colors"
                            >
                                {renderVolumeIcon()}
                            </button>
                            <div className="flex w-14 relative">
                                <input
                                    type="range"
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    value={volume}
                                    onChange={handleVolumeChange}
                                    className="w-full h-1 bg-gray-600 rounded-full appearance-none cursor-pointer 
                     hover:bg-gray-500 transition-all
                     [&::-webkit-slider-thumb]:appearance-none
                     [&::-webkit-slider-thumb]:w-3
                     [&::-webkit-slider-thumb]:h-3
                     [&::-webkit-slider-thumb]:rounded-full
                     [&::-webkit-slider-thumb]:bg-white
                     [&::-webkit-slider-thumb]:hover:bg-white
                     [&::-webkit-slider-thumb]:transition-all
                     [&::-webkit-slider-thumb]:opacity-100" // Suppression de opacity-0 et du group-hover
                                />
                            </div>
                        </div>

                        <button
                            type="button"
                            className="text-gray-400 hover:text-white p-2"
                            onClick={handleTogglePlay}
                        >
                            {isPlaying ? (
                                <Pause size={24} />
                            ) : (
                                <Play size={24} />
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobilePlayerBar;