// contexts/PlayerContext.tsx

import React, { createContext, useState, useContext, useCallback, useMemo, useRef, useEffect } from 'react';
import { Song, PlayerContextType, User } from '../types';

interface PlayerProviderProps {
    children: React.ReactNode;
    songs: Song[];
    onToggleLike: (songId: string) => Promise<void>;
    user: User | null;  // Ajout de l'utilisateur au contexte
}

const PlayerContext = createContext<PlayerContextType | undefined>(undefined);

export const PlayerProvider: React.FC<PlayerProviderProps> = ({
    children,
    songs,
    onToggleLike,
    user
}) => {
    const [currentSong, setCurrentSongState] = useState<Song | null>(null);
    const [currentIndex, setCurrentIndex] = useState<number>(-1);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(0.05);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isRepeatEnabled, setIsRepeatEnabled] = useState(false);
    const [isLiked, setIsLiked] = useState(false);
    const audioRef = useRef<HTMLAudioElement>(new Audio());

    // Appliquer le volume initial à l'élément audio
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume;
        }
    }, []); // Exécuter une seule fois à l'initialisation

    useEffect(() => {
        if (currentSong) {
            const newIndex = songs.findIndex(song => song.id === currentSong.id);
            setCurrentIndex(newIndex);
            setIsLiked(songs[newIndex]?.isLiked || false);
        }
    }, [currentSong, songs]);

    const handleSetCurrentSong = useCallback(async (song: Song): Promise<void> => {
        setCurrentSongState(song);
        const newIndex = songs.findIndex(s => s.id === song.id);
        setCurrentIndex(newIndex);
        if (audioRef.current) {
            audioRef.current.src = song.url;
            audioRef.current.volume = volume; // S'assurer que le nouveau son a le bon volume
            try {
                await audioRef.current.play();
                setIsPlaying(true);
            } catch (error) {
                console.error("Error playing song:", error);
                setIsPlaying(false);
            }
        }
    }, [songs, volume]);

    // Sauvegarder le volume dans le localStorage
    useEffect(() => {
        try {
            const savedVolume = localStorage.getItem('audioVolume');
            if (savedVolume !== null) {
                const parsedVolume = parseFloat(savedVolume);
                setVolume(parsedVolume);
                if (audioRef.current) {
                    audioRef.current.volume = parsedVolume;
                }
            }
        } catch (error) {
            console.error("Error loading saved volume:", error);
        }
    }, []);

    // Sauvegarder le volume quand il change
    useEffect(() => {
        try {
            localStorage.setItem('audioVolume', volume.toString());
        } catch (error) {
            console.error("Error saving volume:", error);
        }
    }, [volume]);

    const togglePlay = useCallback(() => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play().catch(error => {
                    console.error("Error playing audio:", error);
                });
            }
            setIsPlaying(!isPlaying);
        }
    }, [isPlaying]);

    const handleSetVolume = useCallback((newVolume: number) => {
        setVolume(newVolume);
        if (audioRef.current) {
            audioRef.current.volume = newVolume;
        }
    }, []);

    const seekTo = useCallback((time: number) => {
        if (audioRef.current) {
            audioRef.current.currentTime = time;
            setCurrentTime(time);
        }
    }, []);

    const toggleRepeat = useCallback(() => {
        setIsRepeatEnabled(prev => !prev);
    }, []);

    const playNext = useCallback(() => {
        if (currentIndex === -1 || songs.length === 0) return;

        let nextIndex: number;
        if (isRepeatEnabled) {
            nextIndex = currentIndex === songs.length - 1 ? 0 : currentIndex + 1;
        } else {
            if (currentIndex === songs.length - 1) {
                setIsPlaying(false);
                return;
            }
            nextIndex = currentIndex + 1;
        }

        const nextSong = songs[nextIndex];
        if (nextSong) {
            handleSetCurrentSong(nextSong);
        }
    }, [currentIndex, songs, isRepeatEnabled, handleSetCurrentSong]);

    const playPrevious = useCallback(() => {
        if (currentIndex === -1 || songs.length === 0) return;

        if (audioRef.current && audioRef.current.currentTime > 3) {
            audioRef.current.currentTime = 0;
            return;
        }

        let prevIndex: number;
        if (isRepeatEnabled) {
            prevIndex = currentIndex === 0 ? songs.length - 1 : currentIndex - 1;
        } else {
            if (currentIndex === 0) return;
            prevIndex = currentIndex - 1;
        }

        const prevSong = songs[prevIndex];
        if (prevSong) {
            handleSetCurrentSong(prevSong);
        }
    }, [currentIndex, songs, isRepeatEnabled, handleSetCurrentSong]);

    // Créer une Map des likes mémorisée
    const likedSongsMap = useMemo(() => {
        const map = new Map<string, boolean>();
        if (user?.likedSongs) {
            user.likedSongs.forEach(id => map.set(id, true));
        }
        return map;
    }, [user?.likedSongs]);

    // Mettre à jour isLiked quand la chanson change ou que les likes changent
    useEffect(() => {
        if (currentSong?.id) {
            setIsLiked(likedSongsMap.get(currentSong.id) || false);
        }
    }, [currentSong?.id, likedSongsMap]);

    const toggleLikeSong = useCallback(async (songId: string) => {
        try {
            await onToggleLike(songId);
            // Ne pas mettre à jour isLiked ici, il sera mis à jour via l'effet ci-dessus
        } catch (error) {
            console.error('Erreur lors du like:', error);
        }
    }, [onToggleLike]);

    useEffect(() => {
        const handleTimeUpdate = () => {
            if (audioRef.current) {
                setCurrentTime(audioRef.current.currentTime);
            }
        };

        const audio = audioRef.current;
        audio.addEventListener('timeupdate', handleTimeUpdate);
        return () => {
            audio.removeEventListener('timeupdate', handleTimeUpdate);
        };
    }, []);

    useEffect(() => {
        const audio = audioRef.current;
        const handleEnded = () => {
            if (isRepeatEnabled) {
                audio.currentTime = 0;
                audio.play();
            } else {
                playNext();
            }
        };

        audio.addEventListener('ended', handleEnded);
        return () => {
            audio.removeEventListener('ended', handleEnded);
        };
    }, [isRepeatEnabled, playNext]);

    useEffect(() => {
        const handleLoadedMetadata = () => {
            if (audioRef.current) {
                setDuration(audioRef.current.duration);
            }
        };

        const audio = audioRef.current;
        audio.addEventListener('loadedmetadata', handleLoadedMetadata);
        return () => {
            audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
        };
    }, []);

    const contextValue = useMemo(() => ({
        currentSong,
        isPlaying,
        volume,
        currentTime,
        duration,
        audioRef,
        isRepeatEnabled,
        isLiked,
        likedSongsMap, // Exposer la map des likes
        togglePlay,
        setCurrentSong: handleSetCurrentSong,
        setVolume: handleSetVolume,
        seekTo,
        playNext,
        playPrevious,
        toggleRepeat,
        toggleLikeSong,
    }), [
        currentSong,
        isPlaying,
        volume,
        currentTime,
        duration,
        isRepeatEnabled,
        isLiked,
        likedSongsMap, // Ajouter aux dépendances
        togglePlay,
        handleSetCurrentSong,
        handleSetVolume,
        seekTo,
        playNext,
        playPrevious,
        toggleRepeat,
        toggleLikeSong
    ]);

    return (
        <PlayerContext.Provider value={contextValue}>
            {children}
        </PlayerContext.Provider>
    );
};

export const usePlayer = () => {
    const context = useContext(PlayerContext);
    if (context === undefined) {
        throw new Error('usePlayer must be used within a PlayerProvider');
    }
    return context;
};