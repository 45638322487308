import React, { useState } from 'react';
import {
    ChevronLeft,
    Heart,
    MoreVertical,
    Play,
    Pause,
    SkipBack,
    SkipForward,
    Repeat,
    Download,
    Plus
} from "lucide-react";
import { usePlayer } from '../contexts/PlayerContext';
import AddPlaylistModal from './AddPlaylistModal';
import { Playlist, User } from '../types';

interface VideoPlayerBarProps {
    onClose: () => void;
    onLike?: () => void;
    isLiked?: boolean;
    playlists: Playlist[];
    user: User | null;
    addSongToPlaylist: (playlistId: string, songId: string) => Promise<void>;
    createPlaylist: (name: string) => Promise<void>;
}

const VideoPlayerBar: React.FC<VideoPlayerBarProps> = ({
    onClose,
    onLike,
    isLiked = false,
    playlists,
    user,
    addSongToPlaylist,
    createPlaylist
}) => {
    const {
        currentSong,
        isPlaying,
        togglePlay,
        currentTime,
        duration,
        seekTo,
        playNext,
        playPrevious,
        isRepeatEnabled,
        toggleRepeat,
        toggleLikeSong
    } = usePlayer();

    const [showOptions, setShowOptions] = useState(false);
    const [isAddToPlaylistModalOpen, setIsAddToPlaylistModalOpen] = useState(false);
    const [isAddPlaylistModalOpen, setIsAddPlaylistModalOpen] = useState(false);

    const userPlaylists = playlists.filter(playlist => playlist.userId === user?.id);

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
        const time = parseFloat(e.target.value);
        seekTo(time);
    };

    const handleLike = () => {
        if (currentSong && currentSong.id) {
            toggleLikeSong(currentSong.id);
        }
    };

    const handleDownload = () => {
        if (!currentSong?.url) return;

        // Créer un lien de téléchargement
        const downloadLink = document.createElement('a');
        downloadLink.href = currentSong.url;
        downloadLink.download = `${currentSong.name.replace(/[^a-z0-9]/gi, '_').toLowerCase()}_${currentSong.artist.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.mp3`;

        // Ajouter le lien au DOM, cliquer dessus, puis le retirer
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        setShowOptions(false);
    };

    const handlePlaylistSelect = async (playlistId: string) => {
        if (currentSong?.id) {
            await addSongToPlaylist(playlistId, currentSong.id);
            setIsAddToPlaylistModalOpen(false);
        }
    };

    const handleAddPlaylist = async (name: string): Promise<void> => {
        try {
            const newPlaylist = await createPlaylist(name);
            setIsAddPlaylistModalOpen(false);
        } catch (error) {
            console.error("Erreur lors de la création de la playlist:", error);
        }
    };

    if (!currentSong) return null;

    return (
        <>
            {/* Top Bar - Toujours en haut */}
            <div className="fixed top-0 left-0 right-0 p-4 flex justify-between items-center bg-gradient-to-b from-black/80 via-black/40 to-transparent z-50">
                <button onClick={onClose} className="text-white p-2">
                    <ChevronLeft size={24} />
                </button>
                <div className="flex-1 text-center mx-4">
                    <h2 className="text-white font-semibold truncate">
                        {currentSong.name}
                    </h2>
                </div>
                <div className="flex items-center space-x-2">
                    <button
                        onClick={handleLike}
                        className={`text-${isLiked ? 'red' : 'white'}-500 hover:text-red-500 p-2`}
                    >
                        <Heart size={24} fill={isLiked ? 'currentColor' : 'none'} />
                    </button>
                </div>
            </div>

            {/* Bottom Controls - Ajusté pour la barre de navigation */}
            <div className="fixed bottom-14 left-0 right-0 p-4 pb-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent">
                {/* Progress Bar */}
                <div className="mb-4">
                    <div className="flex justify-between text-xs text-white mb-1">
                        <span>{formatTime(currentTime)}</span>
                        <span>{formatTime(duration)}</span>
                    </div>
                    <div className="relative h-1 bg-gray-600 rounded">
                        <input
                            type="range"
                            min={0}
                            max={duration || 0}
                            value={currentTime}
                            onChange={handleSeek}
                            className="absolute inset-0 w-full opacity-0 cursor-pointer"
                        />
                        <div
                            className="absolute h-full bg-green-500 rounded"
                            style={{ width: `${(currentTime / duration) * 100}%` }}
                        />
                    </div>
                </div>

                {/* Controls */}
                <div className="flex justify-between items-center mb-2 sm:mb-4">
                    <button
                        onClick={toggleRepeat}
                        className={`p-2 transition-all duration-150 ease-in-out ${isRepeatEnabled
                                ? 'text-green-500 scale-110'
                                : 'text-white hover:text-gray-300'
                            }`}
                        aria-label={isRepeatEnabled ? 'Désactiver la répétition' : 'Activer la répétition'}
                    >
                        <div className="relative">
                            <Repeat size={24} />
                            {isRepeatEnabled && (
                                <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-1.5 h-1.5 bg-green-500 rounded-full" />
                            )}
                        </div>
                    </button>

                    <div className="flex items-center space-x-8">
                        <button
                            onClick={playPrevious}
                            className="text-white hover:text-gray-300 transition-colors p-2"
                        >
                            <SkipBack size={24} />
                        </button>

                        <button
                            onClick={togglePlay}
                            className="bg-white hover:bg-gray-200 transition-colors rounded-full p-4"
                        >
                            {isPlaying ? (
                                <Pause size={24} className="text-black" />
                            ) : (
                                <Play size={24} className="text-black" />
                            )}
                        </button>

                        <button
                            onClick={playNext}
                            className="text-white hover:text-gray-300 transition-colors p-2"
                        >
                            <SkipForward size={24} />
                        </button>
                    </div>

                    {/* Contrôles et options */}
                    <div className="relative">
                        <button
                            onClick={() => setShowOptions(!showOptions)}
                            className={`text-white hover:text-gray-300 transition-colors p-2 ${showOptions ? 'text-green-500' : ''
                                }`}
                        >
                            <MoreVertical size={24} />
                        </button>

                        {showOptions && (
                            <div className="absolute w-52 bottom-full right-0 mb-2 bg-gray-800 rounded-lg shadow-lg">
                                <button
                                    onClick={() => {
                                        setIsAddToPlaylistModalOpen(true);
                                        setShowOptions(false);
                                    }}
                                    className="flex items-center space-x-2 px-4 py-2 hover:bg-gray-700 w-full text-left"
                                >
                                    <Plus size={20} />
                                    <span>Ajouter à la playlist</span>
                                </button>
                                <button
                                    onClick={handleDownload}
                                    className="flex items-center space-x-2 px-4 py-2 hover:bg-gray-700 w-full text-left"
                                >
                                    <Download size={20} />
                                    <span>Télécharger</span>
                                </button>
                            </div>
                        )}

                        {/* Modal d'ajout à une playlist */}
                        {isAddToPlaylistModalOpen && (
                            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                                <div className="bg-gray-800 p-6 rounded-lg w-80">
                                    <h3 className="text-xl font-bold mb-4">Ajouter à une playlist</h3>
                                    <div className="space-y-2">
                                        <button
                                            type="button"
                                            onClick={() => setIsAddPlaylistModalOpen(true)}
                                            className="w-full text-left py-2 px-4 hover:bg-gray-700 rounded mb-2"
                                        >
                                            Créer une nouvelle playlist
                                        </button>
                                        {playlists
                                            .filter(playlist => playlist.userId === user?.id)
                                            .map((playlist) => (
                                                <button
                                                    type="button"
                                                    key={playlist.id}
                                                    onClick={() => handlePlaylistSelect(playlist.id!)}
                                                    className="w-full text-left py-2 px-4 hover:bg-gray-700 rounded"
                                                >
                                                    {playlist.name}
                                                </button>
                                            ))}
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => setIsAddToPlaylistModalOpen(false)}
                                        className="mt-4 w-full bg-red-500 text-white py-2 px-4 rounded"
                                    >
                                        Annuler
                                    </button>
                                </div>
                            </div>
                        )}

                        {/* Modal de création de playlist */}
                        {isAddPlaylistModalOpen && (
                            <AddPlaylistModal
                                isOpen={isAddPlaylistModalOpen}
                                onClose={() => setIsAddPlaylistModalOpen(false)}
                                onAddPlaylist={createPlaylist}
                            />
                        )}
                    </div>
                </div>
            </div>

        </>
    );
};

export default VideoPlayerBar;