// src/components/Home.tsx
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Plus, Heart } from "lucide-react";
import { FaPlay, FaPause } from "react-icons/fa";
import { usePlayer } from '../contexts/PlayerContext';
import { Song, User, Playlist, HomeProps } from '../types';
import AddPlaylistModal from './AddPlaylistModal'; // Ajout de l'import

const Home: React.FC<HomeProps> = ({
    songs,
    currentSong,
    isPlaying,
    user,
    playlists,
    setCurrentSong,
    togglePlay,
    likedSongs,
    handleLike,
    addSongToPlaylist,
    setIsVideoPlayerOpen,
    createPlaylist
}) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const [selectedSong, setSelectedSong] = useState<Song | null>(null);
    const [isAddToPlaylistModalOpen, setIsAddToPlaylistModalOpen] = useState(false);
    const [isAddPlaylistModalOpen, setIsAddPlaylistModalOpen] = useState(false);
    const { likedSongsMap } = usePlayer();

    const userPlaylists = playlists.filter(playlist => playlist.userId === user?.id);

    // Utilisation de useMemo pour optimiser la vérification des likes
    const isLikedMap = useMemo(() => {
        if (!user?.likedSongs) return new Map();
        return new Map(user.likedSongs.map(id => [id, true]));
    }, [user?.likedSongs]);

    const handlePlayClick = async (e: React.MouseEvent<HTMLButtonElement>, song: Song) => {
        e.preventDefault();
        e.stopPropagation();

        if (currentSong?.id === song.id) {
            togglePlay();
            setIsVideoPlayerOpen(true);
        } else {
            await setCurrentSong(song);
            setIsVideoPlayerOpen(true);
        }
    };

    const handleImageClick = (song: Song) => {
        if (song.videoUrl) {
            if (currentSong?.id !== song.id) {
                setCurrentSong(song);
            }
            setIsVideoPlayerOpen(true);
        }
    };

    const handleAddToPlaylist = (song: Song) => {
        setSelectedSong(song);
        setIsAddToPlaylistModalOpen(true);
    };

    const handlePlaylistSelect = async (playlistId: string) => {
        if (selectedSong && selectedSong.id) {
            await addSongToPlaylist(playlistId, selectedSong.id);
            setIsAddToPlaylistModalOpen(false);
            setSelectedSong(null);
        }
    };

    const handleAddPlaylist = async (name: string): Promise<void> => {
        await createPlaylist(name);
        setIsAddPlaylistModalOpen(false);
    };


    return (
        <div className="flex flex-col min-h-screen bg-black text-white">
            <div className="bg-gradient-to-r from-purple-600 to-indigo-600 p-4 text-center">
                <p className="text-white font-bold">
                    <a href="https://rozo-atelier.com">Nouvelle collection disponible dès maintenant !</a>
                </p>
            </div>

            <header className="flex justify-center items-center p-4">
                <img src="logo.png" alt="Logo" className="w-20 h-20 mb-4" />
            </header>

            <main className="flex-grow overflow-y-auto px-4 pb-32">
                <h1 className="text-2xl font-bold mb-6">
                    Bienvenue, {user?.name || 'Utilisateur'}
                </h1>

                <div className="mb-6">
                    <h2 className="text-xl font-bold mb-4">Mes Instrumentales</h2>
                    <div
                        ref={scrollRef}
                        className="flex space-x-4 overflow-x-auto scrollbar-hide"
                        style={{ scrollBehavior: 'smooth' }}
                    >
                        {songs.map((song) => (
                            <div key={song.id} className="flex-shrink-0 w-40">
                                <div className="relative">
                                    <img
                                        src={song.thumbnailUrl || "/placeholder-image.png"}
                                        alt={song.name}
                                        className={`w-full h-40 object-cover rounded-lg cursor-pointer ${currentSong?.id === song.id && isPlaying ? 'spinning' : ''
                                            }`}
                                        onClick={() => handleImageClick(song)}
                                    />
                                    <button
                                        type="button"
                                        onClick={(e) => handlePlayClick(e, song)}
                                        className="absolute bottom-2 right-2 bg-green-500 text-white rounded-full p-2 hover:bg-green-600"
                                    >
                                        {currentSong?.id === song.id && isPlaying ? (
                                            <FaPause size={15} />
                                        ) : (
                                            <FaPlay size={15} />
                                        )}
                                    </button>
                                </div>
                                <div className="flex justify-between items-center mt-2">
                                    <div className="flex space-x-2">
                                        <button
                                            type="button"
                                            onClick={() => handleAddToPlaylist(song)}
                                            className="text-white hover:text-green-500"
                                        >
                                            <Plus size={20} />
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => song.id && handleLike(song.id)}
                                            className={`hover:text-red-500 transition-colors ${likedSongs.includes(song.id || '') ? 'text-red-500' : 'text-white'
                                                }`}
                                        >
                                            <Heart
                                                size={20}
                                                fill={likedSongs.includes(song.id || '') ? "currentColor" : "none"}
                                            />
                                        </button>
                                    </div>
                                </div>
                                <p className="mt-2 font-semibold text-sm truncate">{song.name}</p>
                                <p className="text-xs text-gray-400 truncate">{song.artist}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </main>

            {/* Modal d'ajout à une playlist */}
            {isAddToPlaylistModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-gray-800 p-6 rounded-lg w-80">
                        <h3 className="text-xl font-bold mb-4">Ajouter à une playlist</h3>
                        <div className="space-y-2">
                            <button
                                type="button"
                                onClick={() => setIsAddPlaylistModalOpen(true)}
                                className="w-full text-left py-2 px-4 hover:bg-gray-700 rounded mb-2"
                            >
                                Créer une nouvelle playlist
                            </button>
                            {userPlaylists.map((playlist) => (
                                <button
                                    type="button"
                                    key={playlist.id}
                                    onClick={() => handlePlaylistSelect(playlist.id!)}
                                    className="w-full text-left py-2 px-4 hover:bg-gray-700 rounded"
                                >
                                    {playlist.name}
                                </button>
                            ))}
                        </div>
                        <button
                            type="button"
                            onClick={() => setIsAddToPlaylistModalOpen(false)}
                            className="mt-4 w-full bg-red-500 text-white py-2 px-4 rounded"
                        >
                            Annuler
                        </button>
                    </div>
                </div>
            )}

            {/* Modal de création de playlist */}
            {isAddPlaylistModalOpen && (
                <AddPlaylistModal
                    isOpen={isAddPlaylistModalOpen}
                    onClose={() => setIsAddPlaylistModalOpen(false)}
                    onAddPlaylist={handleAddPlaylist}
                />
            )}
        </div>
    );
};

export default Home;