// src/components/Profile.tsx
import React, { useState, useRef, useEffect } from 'react';
import {
    Camera,
    LogOut,
    Eye,
    EyeOff,
    Settings,
    Check,
    X
} from "lucide-react";
import { User, Playlist, ProfileProps } from '../types';
import DOMPurify from 'dompurify';
import { Timestamp } from 'firebase/firestore';


type SettingType = 'password' | 'email' | 'name' | null;

const Profile: React.FC<ProfileProps> = ({
    user,
    playlists,
    logout,
    changePassword,
    changeEmail,
    changeName,
    showOldPassword,
    setShowOldPassword,
    showNewPassword,
    setShowNewPassword,
    showConfirmPassword,
    setShowConfirmPassword
}) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [newName, setNewName] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [isEditingAvatar, setIsEditingAvatar] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [selectedSetting, setSelectedSetting] = useState<SettingType>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [avatarFile, setAvatarFile] = useState<File | null>(null);
    const [currentPassword, setCurrentPassword] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);

    const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setAvatarFile(file);
            setIsEditingAvatar(false);
            // Ici, vous devriez implémenter la logique de mise à jour de l'avatar
        }
    };

    const handleSettingClick = (setting: SettingType) => {
        setSelectedSetting(setting);
        setIsSettingsOpen(false);
        setError(null);
        setSuccess(null);
    };

    const handleChangePassword = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);
        setIsLoading(true);

        if (newPassword !== confirmPassword) {
            setError("Les mots de passe ne correspondent pas");
            setIsLoading(false);
            return;
        }

        try {
            await changePassword(oldPassword, newPassword);
            setSuccess('Mot de passe modifié avec succès');
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setTimeout(() => {
                setSelectedSetting(null);
            }, 2000);
        } catch (error) {
            setError("Erreur lors du changement de mot de passe");
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeEmail = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);
        setIsLoading(true);

        try {
            await changeEmail(newEmail, currentPassword);
            setSuccess(
                "Un email de vérification a été envoyé à votre nouvelle adresse. " +
                "Veuillez cliquer sur le lien dans l'email pour confirmer le changement. " +
                "Votre adresse email ne sera mise à jour qu'après la vérification."
            );
            setNewEmail('');
            setCurrentPassword('');
            setTimeout(() => {
                setSelectedSetting(null);
            });
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError("Une erreur est survenue lors du changement d'email");
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeName = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);

        try {
            await changeName(newName);
            setSuccess('Nom d\'utilisateur mis à jour avec succès.');
            // Ne pas vider newName immédiatement pour éviter un flash de l'interface
            setTimeout(() => {
                setNewName('');
            }, 2000);
        } catch (err) {
            setError('Une erreur est survenue. Veuillez réessayer.');
            console.error(err);
        }
    };

    // Dans le rendu, modifiez la partie avec Timestamp :
    const formatDate = (timestamp: any) => {
        if (!timestamp) return 'N/A';

        try {
            if (timestamp instanceof Timestamp) {
                return timestamp.toDate().toLocaleDateString();
            }

            if (timestamp.seconds) {
                return new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate().toLocaleDateString();
            }

            if (timestamp instanceof Date) {
                return timestamp.toLocaleDateString();
            }

            return 'N/A';
        } catch (error) {
            console.error('Error formatting date:', error);
            return 'N/A';
        }
    };

    if (!user) return <div>Veuillez vous connecter pour voir votre profil.</div>;

    return (
        <div className="p-4">
            {/* Header */}
            <div className="flex items-center justify-between mb-6">
                <div className="flex items-center">
                    <div className="relative mr-4">
                        <img
                            src={user.photoURL || "/default-avatar.png"}
                            alt="Avatar"
                            className="w-16 h-16 rounded-full object-cover"
                            onClick={() => setIsEditingAvatar(true)}
                        />
                        {isEditingAvatar && (
                            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full">
                                <label htmlFor="avatar-upload" className="cursor-pointer">
                                    <Camera size={24} className="text-white" />
                                </label>
                                <input
                                    id="avatar-upload"
                                    type="file"
                                    accept="image/*"
                                    className="hidden"
                                    onChange={handleAvatarChange}
                                />
                            </div>
                        )}
                    </div>
                    <h2 className="text-2xl font-bold">{user.name || 'Utilisateur'}</h2>
                </div>

                <div className="flex items-center space-x-2">
                    <div className="relative pt-1">
                        <button
                            onClick={() => setIsSettingsOpen(!isSettingsOpen)}
                            className="text-gray-400 hover:text-white"
                        >
                            <Settings size={24} />
                        </button>
                        {isSettingsOpen && (
                            <div className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-md shadow-lg z-10">
                                <button
                                    onClick={() => handleSettingClick('password')}
                                    className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-700"
                                >
                                    Changer le mot de passe
                                </button>
                                <button
                                    onClick={() => handleSettingClick('email')}
                                    className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-700"
                                >
                                    Changer l'email
                                </button>
                                <button
                                    onClick={() => handleSettingClick('name')}
                                    className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-700"
                                >
                                    Changer le nom d'utilisateur
                                </button>
                            </div>
                        )}
                    </div>
                    <button
                        onClick={logout}
                        className="text-red-500 hover:text-red-700"
                    >
                        <LogOut size={24} />
                    </button>
                </div>
            </div>

            {/* Email display */}
            <p className="mb-4 text-gray-300">Email: {user.email}</p>

            {/* Settings Modals */}
            {selectedSetting === 'password' && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                    <div className="bg-gray-800 p-6 rounded-lg w-96">
                        <h2 className="text-xl font-bold mb-4">Changer le mot de passe</h2>
                        <form onSubmit={handleChangePassword}>
                            <div className="relative mb-4">
                                <input
                                    type={showOldPassword ? "text" : "password"}
                                    placeholder="Ancien mot de passe"
                                    value={DOMPurify.sanitize(oldPassword)}
                                    onChange={(e) => setOldPassword(DOMPurify.sanitize(e.target.value))}
                                    className="w-full p-2 bg-gray-700 text-white rounded"
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={() => setShowOldPassword(!showOldPassword)}
                                    className="absolute right-2 top-2 text-gray-400"
                                >
                                    {showOldPassword ? <Eye size={20} /> : <EyeOff size={20} />}
                                </button>
                            </div>

                            <div className="relative mb-4">
                                <input
                                    type={showNewPassword ? "text" : "password"}
                                    placeholder="Nouveau mot de passe"
                                    value={DOMPurify.sanitize(newPassword)}
                                    onChange={(e) => setNewPassword(DOMPurify.sanitize(e.target.value))}
                                    className="w-full p-2 bg-gray-700 text-white rounded"
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                    className="absolute right-2 top-2 text-gray-400"
                                >
                                    {showNewPassword ? <Eye size={20} /> : <EyeOff size={20} />}
                                </button>
                            </div>

                            <div className="relative mb-4">
                                <input
                                    type={showConfirmPassword ? "text" : "password"}
                                    placeholder="Confirmer le nouveau mot de passe"
                                    value={DOMPurify.sanitize(confirmPassword)}
                                    onChange={(e) => setConfirmPassword(DOMPurify.sanitize(e.target.value))}
                                    className="w-full p-2 bg-gray-700 text-white rounded"
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    className="absolute right-2 top-2 text-gray-400"
                                >
                                    {showConfirmPassword ? <Eye size={20} /> : <EyeOff size={20} />}
                                </button>
                            </div>

                            {error && <p className="text-red-500 mb-4">{error}</p>}
                            {success && <p className="text-green-500 mb-4">{success}</p>}

                            <div className="flex justify-end space-x-2">
                                <button
                                    type="button"
                                    onClick={() => setSelectedSetting(null)}
                                    className="px-4 py-2 bg-gray-700 text-white rounded hover:bg-gray-600"
                                >
                                    Annuler
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-500"
                                    disabled={isLoading}
                                >
                                    {isLoading ? "Modification..." : "Modifier"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {selectedSetting === 'email' && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                    <div className="bg-gray-800 p-6 rounded-lg w-96">
                        <h2 className="text-xl font-bold mb-4">Changer l'email</h2>
                        <form onSubmit={handleChangeEmail}>
                            <input
                                type="email"
                                placeholder="Nouvelle adresse email"
                                value={DOMPurify.sanitize(newEmail)}
                                onChange={(e) => setNewEmail(DOMPurify.sanitize(e.target.value))}
                                className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
                                required
                            />

                            <div className="relative mb-4">
                                <input
                                    type={showCurrentPassword ? "text" : "password"}
                                    placeholder="Mot de passe actuel"
                                    value={DOMPurify.sanitize(currentPassword)}
                                    onChange={(e) => setCurrentPassword(DOMPurify.sanitize(e.target.value))}
                                    className="w-full p-2 bg-gray-700 text-white rounded"
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                                    className="absolute right-2 top-2 text-gray-400"
                                >
                                    {showCurrentPassword ? <Eye size={20} /> : <EyeOff size={20} />}
                                </button>
                            </div>

                            {error && <p className="text-red-500 mb-4">{error}</p>}
                            {success && <p className="text-green-500 mb-4">{success}</p>}

                            <div className="flex justify-end space-x-2">
                                <button
                                    type="button"
                                    onClick={() => setSelectedSetting(null)}
                                    className="px-4 py-2 bg-gray-700 text-white rounded hover:bg-gray-600"
                                >
                                    Annuler
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-500"
                                    disabled={isLoading}
                                >
                                    {isLoading ? "Modification..." : "Modifier"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {selectedSetting === 'name' && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                    <div className="bg-gray-800 p-6 rounded-lg w-96">
                        <h2 className="text-xl font-bold mb-4">Changer le nom d'utilisateur</h2>
                        <form onSubmit={handleChangeName}>
                            <input
                                type="text"
                                placeholder="Nouveau nom d'utilisateur (max 12 caractères)"
                                value={DOMPurify.sanitize(newName)}
                                onChange={(e) => setNewName(DOMPurify.sanitize(e.target.value).slice(0, 12))}
                                className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
                                required
                            />

                            {error && <p className="text-red-500 mb-4">{error}</p>}
                            {success && <p className="text-green-500 mb-4">{success}</p>}

                            <div className="flex justify-end space-x-2">
                                <button
                                    type="button"
                                    onClick={() => setSelectedSetting(null)}
                                    className="px-4 py-2 bg-gray-700 text-white rounded hover:bg-gray-600"
                                >
                                    Annuler
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-500"
                                    disabled={isLoading}
                                >
                                    {isLoading ? "Modification..." : "Modifier"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* User Stats */}
            <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="bg-gray-800 p-4 rounded-lg">
                    <h3 className="text-lg font-semibold mb-2">Playlists</h3>
                    <p className="text-2xl font-bold">
                        {playlists.filter(p => p.userId === user.id).length || 1}
                    </p>
                </div>
                <div className="bg-gray-800 p-4 rounded-lg">
                    <h3 className="text-lg font-semibold mb-2">Titres likés</h3>
                    <p className="text-2xl font-bold">
                        {user.likedSongs?.length || 0}
                    </p>
                </div>
                <div className="bg-gray-800 p-4 rounded-lg">
                    <h3 className="text-lg font-semibold mb-2">Membre depuis</h3>
                    <p className="text-2xl font-bold">
                        {user?.createdAt ? formatDate(user.createdAt) : 'N/A'}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Profile;