// src/components/AppContent.tsx
import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { usePlayer } from '../contexts/PlayerContext';
import { useMediaQuery } from '../hooks/useMediaQuery';
import { AppContentProps, Playlist, Song } from '../types';
import VideoPlayer from './VideoPlayer';
import MobilePlayerBar from './MobilePlayerBar';
import BottomNavigation from './BottomNavigation';
import Home from './Home';
import Collection from './Collection';
import PlaylistView from './PlaylistView';
import AlbumPage from './AlbumPage';
import Profile from './Profile';
import { Timestamp, addDoc, collection } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from './firebaseConfig';

const AppContent: React.FC<AppContentProps> = ({
    user,
    songs,
    playlists,
    albums,
    isCreatePlaylistModalOpen,
    isEditPlaylistModalOpen,
    isAuthModalOpen,
    isVideoPlayerOpen,
    currentEditPlaylist,
    showOldPassword,
    showNewPassword,
    showConfirmPassword,
    createPlaylist,
    setPlaylists,
    setShowOldPassword,
    setShowNewPassword,
    setShowConfirmPassword,
    setIsCreatePlaylistModalOpen,
    setIsEditPlaylistModalOpen,
    setIsAuthModalOpen,
    setIsVideoPlayerOpen,
    setCurrentEditPlaylist,
    addInstrumental,
    toggleLikeSong,
    addSongToPlaylist,
    removeSongFromPlaylist,
    deletePlaylist,
    updatePlaylistImage,
    updatePlaylistName,
    changePassword,
    changeEmail,
    changeName,
    logout
}) => {
    const {
        currentSong,
        isPlaying,
        setCurrentSong,
        togglePlay
    } = usePlayer();

    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const [currentSongIndex, setCurrentSongIndex] = useState(0);

    // Centralisation de la logique de like
    const handleLike = async (songId: string) => {
        try {
            await toggleLikeSong(songId);
        } catch (error) {
            console.error("Erreur lors du like :", error);
        }
    };

    const handleCreatePlaylist = async (name: string): Promise<void> => {
        try {
            const auth = getAuth();
            const currentUser = auth.currentUser;

            if (!currentUser) {
                throw new Error("Utilisateur non connecté");
            }

            const newPlaylist: Omit<Playlist, 'id'> = {
                name: name.trim(),
                songs: [],
                imageUrl: "/placeholder_playlist.PNG",
                userId: currentUser.uid,
                createdAt: Timestamp.now(),
                isSystem: false
            };

            const docRef = await addDoc(collection(db, "playlists"), newPlaylist);

            const playlistWithId: Playlist = {
                ...newPlaylist,
                id: docRef.id,
            };

            // Mise à jour de l'état local si setPlaylists est disponible
            if (setPlaylists) {
                setPlaylists((prevPlaylists: Playlist[]) => [...prevPlaylists, playlistWithId]);
            }

            setIsCreatePlaylistModalOpen(false);
            console.log('Playlist créée avec succès:', playlistWithId.name);

        } catch (error) {
            console.error('Erreur lors de la création de la playlist:', error);
            throw new Error('Échec de la création de la playlist');
        }
    };

    const openEditPlaylistModal = (playlist: Playlist) => {
        setCurrentEditPlaylist(playlist);
        setIsEditPlaylistModalOpen(true);
    };

    const handlePlayClick = async (e: React.MouseEvent<HTMLButtonElement>, song: Song): Promise<void> => {
        e.preventDefault();
        e.stopPropagation();

        if (currentSong?.id === song.id) {
            togglePlay();
            setIsVideoPlayerOpen(true);
        } else {
            await setCurrentSong(song);
            setIsVideoPlayerOpen(true);
        }
    };

    return (
        <div className={`flex flex-col min-h-screen relative ${isDesktop && isVideoPlayerOpen ? 'pr-1/5' : ''
            }`}>
            <div className="flex-grow pb-16">
                <Routes>
                    <Route
                        path="/"
                        element={
                            <Home
                                songs={songs}
                                currentSong={currentSong}
                                isPlaying={isPlaying}
                                user={user}
                                playlists={playlists}
                                setCurrentSong={setCurrentSong}
                                togglePlay={togglePlay}
                                likedSongs={user?.likedSongs || []}
                                handleLike={handleLike}
                                addSongToPlaylist={addSongToPlaylist}
                                setIsVideoPlayerOpen={setIsVideoPlayerOpen}
                                createPlaylist={handleCreatePlaylist}
                            />
                        }
                    />
                    <Route
                        path="/collection"
                        element={
                            <Collection
                                currentSong={currentSong}
                                isPlaying={isPlaying}
                                playlists={playlists}
                                instrumentals={songs}
                                onAddPlaylist={handleCreatePlaylist}
                                onAddInstrumental={addInstrumental}
                                addSongToPlaylist={addSongToPlaylist}
                                createPlaylist={createPlaylist}
                                user={user}
                                setCurrentSong={setCurrentSong}
                                contextSetCurrentSong={setCurrentSong}
                                contextTogglePlay={togglePlay}
                                setCurrentSongIndex={setCurrentSongIndex}
                                likedSongs={user?.likedSongs || []}
                                handleLike={handleLike}
                                setIsVideoPlayerOpen={setIsVideoPlayerOpen}
                                updatePlaylistName={updatePlaylistName}
                                updatePlaylistImage={updatePlaylistImage}
                                deletePlaylist={deletePlaylist}
                            />
                        }
                    />
                    <Route
                        path="/playlist/:id"
                        element={
                            <PlaylistView
                                user={user}
                                playlists={playlists}
                                songs={songs}
                                currentSong={currentSong}
                                isPlaying={isPlaying}
                                togglePlay={togglePlay}
                                setCurrentSong={setCurrentSong}
                                addSongToPlaylist={addSongToPlaylist}
                                removeSongFromPlaylist={removeSongFromPlaylist}
                                openEditPlaylistModal={openEditPlaylistModal}
                                deletePlaylist={deletePlaylist}
                                toggleLikeSong={toggleLikeSong}
                                updatePlaylistImage={updatePlaylistImage}
                                handlePlayClick={handlePlayClick}
                                updatePlaylistName={updatePlaylistName}
                                setIsVideoPlayerOpen={setIsVideoPlayerOpen}
                            />
                        }
                    />
                    <Route
                        path="/album"
                        element={
                            <AlbumPage
                                user={user}
                                songs={songs}
                                onAddInstrumental={addInstrumental}
                                currentSong={currentSong}
                                contextSetCurrentSong={setCurrentSong}
                                contextTogglePlay={togglePlay}
                                playlists={playlists}
                                addSongToPlaylist={addSongToPlaylist}
                                createPlaylist={createPlaylist}
                                setIsVideoPlayerOpen={setIsVideoPlayerOpen}
                            />
                        }
                    />
                    <Route
                        path="/profile"
                        element={
                            <Profile
                                user={user}
                                playlists={playlists}
                                logout={logout}
                                changePassword={changePassword}
                                changeEmail={changeEmail}
                                changeName={changeName}
                                showOldPassword={showOldPassword}
                                setShowOldPassword={setShowOldPassword}
                                showNewPassword={showNewPassword}
                                setShowNewPassword={setShowNewPassword}
                                showConfirmPassword={showConfirmPassword}
                                setShowConfirmPassword={setShowConfirmPassword}
                            />
                        }
                    />
                </Routes>
            </div>

            {isVideoPlayerOpen && currentSong?.videoUrl && (
                <VideoPlayer
                    onClose={() => setIsVideoPlayerOpen(false)}
                    playlists={playlists}
                    user={user}
                    addSongToPlaylist={addSongToPlaylist}
                    createPlaylist={createPlaylist}
                />
            )}

            <div className="fixed bottom-0 left-0 right-0 z-50">
                {currentSong && !isVideoPlayerOpen && (
                    <MobilePlayerBar
                        currentSong={currentSong}
                        isPlaying={isPlaying}
                        togglePlay={togglePlay}
                        setIsVideoPlayerOpen={setIsVideoPlayerOpen}
                        isVideoPlayerOpen={isVideoPlayerOpen}
                    />
                )}
                <BottomNavigation className="h-14 bg-black border-t border-gray-800" />
            </div>
        </div>
    );
};

export default AppContent;