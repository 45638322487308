import React, { useRef, useEffect } from 'react';
import { ChevronLeft, Play, Pause, Shuffle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Song, PlaylistHeaderProps } from '../types';
import { Timestamp } from 'firebase/firestore';

const PlaylistHeader: React.FC<PlaylistHeaderProps> = ({
    playlistName,
    createdAt,
    totalSongs,
    totalDuration,
    currentSong,
    isPlaying,
    onPlayPause,
    playlistImageUrl,
    onShuffleClick,
    isShuffleEnabled,
    showPlaylistImage
}) => {
    const navigate = useNavigate();
    const videoRef = useRef<HTMLVideoElement>(null);

    // Synchroniser l'état de lecture de la vidéo
    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            if (isPlaying) {
                video.play().catch(e => console.error('Video play error:', e));
            } else {
                video.pause();
            }
        }
    }, [isPlaying]);

    const formatDate = (timestamp: Timestamp) => {
        return timestamp.toDate().toLocaleDateString('fr-FR', { year: 'numeric' });
    };

    const formatTotalDuration = (totalSeconds: number) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        if (hours > 0) {
            return `${hours}h ${minutes}min ${seconds}s`;
        }
        return `${minutes}min ${seconds}s`;
    };

    const handleMainPlayPause = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onPlayPause();
    };

    return (
        <div className="space-y-4">
            <div className="relative aspect-square overflow-hidden">
                <button
                    onClick={() => navigate(-1)}
                    className="absolute top-4 left-4 w-10 h-10 bg-white rounded-full flex items-center justify-center z-10"
                >
                    <ChevronLeft size={24} className="text-black" />
                </button>

                {!showPlaylistImage && currentSong?.videoUrl ? (
                    <video
                        ref={videoRef}
                        src={currentSong.videoUrl}
                        className="w-full h-full object-cover"
                        autoPlay
                        loop
                        muted
                        playsInline
                    />
                ) : (
                    <img
                        src={playlistImageUrl}
                        alt={playlistName}
                        className="w-full h-full object-cover"
                    />
                )}

                <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/80 to-transparent">
                    <div className="flex justify-between items-end">
                        <div>
                            <h3 className="text-lg font-bold text-white">
                                {currentSong?.name || playlistName}
                            </h3>
                            <p className="text-sm text-gray-300">
                                {currentSong?.artist || ''}
                            </p>
                        </div>
                        <div className="flex items-center space-x-3">
                            <button
                                onClick={onShuffleClick}
                                className={`w-10 h-10 rounded-full flex items-center justify-center ${isShuffleEnabled
                                    ? 'bg-green-500 text-white'
                                    : 'bg-white/20 text-white hover:bg-white/30'
                                    }`}
                                aria-label="Lecture aléatoire"
                            >
                                <Shuffle size={20} />
                            </button>
                            <button
                                onClick={handleMainPlayPause}
                                className="w-12 h-12 bg-white rounded-full flex items-center justify-center"
                                aria-label={isPlaying ? 'Pause' : 'Lecture'}
                            >
                                {isPlaying ? (
                                    <Pause size={24} className="text-black" />
                                ) : (
                                    <Play size={24} className="text-black ml-1" />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-between items-center text-sm text-gray-300 px-5">
                <div className="flex items-center space-x-2">
                    <span>{playlistName}</span>
                    <span className="w-1.5 h-1.5 rounded-full bg-gray-300"></span>
                    <span>{formatDate(createdAt)}</span>
                </div>
                <div className="flex items-center space-x-2">
                    <span>{totalSongs} titres</span>
                    <span className="w-1.5 h-1.5 rounded-full bg-gray-300"></span>
                    <span>{formatTotalDuration(totalDuration)}</span>
                </div>
            </div>
        </div>
    );
};

export default PlaylistHeader;