// src/components/firebaseConfig.tsx
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    // Votre configuration Firebase ici
    apiKey: "AIzaSyAdr1m5L6I6_ZBZxoSN3QRbjUG87xqSwnc",
    authDomain: "music-streaming-app-1296d.firebaseapp.com",
    projectId: "music-streaming-app-1296d",
    storageBucket: "music-streaming-app-1296d.appspot.com",
    messagingSenderId: "820031322331",
    appId: "1:820031322331:web:100e0aed129c642b6c74fe",
    measurementId: "G-6RZBYM3W53",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getFirestore(app);

export { app, db, storage };
