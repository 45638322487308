// src/components/Collection.tsx
import React, { useState, useRef, useEffect } from 'react';
import { Plus, Search as SearchIcon, ChevronLeft, Heart } from "lucide-react";
import { useNavigate } from 'react-router-dom';
import { FaPlay, FaPause } from "react-icons/fa";
import { Timestamp } from 'firebase/firestore';
import { Song, User, Playlist, CollectionProps } from '../types';
import { usePlayer } from '../contexts/PlayerContext';
import AddPlaylistModal from './AddPlaylistModal';
import AddInstrumentalModal from './AddInstrumentalModal';
import PlaylistActions from './PlaylistActions';


const Collection: React.FC<CollectionProps> = ({
    currentSong,
    handleLike,
    likedSongs,
    playlists,
    instrumentals,
    onAddPlaylist,
    onAddInstrumental,
    addSongToPlaylist,
    user,
    setIsVideoPlayerOpen,
    createPlaylist,
    setCurrentSong,
    isPlaying,
    contextSetCurrentSong,
    contextTogglePlay,
    setCurrentSongIndex,
    updatePlaylistName,
    updatePlaylistImage,
    deletePlaylist
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isAddPlaylistModalOpen, setIsAddPlaylistModalOpen] = useState(false);
    const [isAddInstrumentalModalOpen, setIsAddInstrumentalModalOpen] = useState(false);
    const [selectedSong, setSelectedSong] = useState<Song | null>(null);
    const [isPlaylistListOpen, setIsPlaylistListOpen] = useState(false);
    const [isAddToPlaylistModalOpen, setIsAddToPlaylistModalOpen] = useState(false);
    const navigate = useNavigate();
    const searchRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const scrollRef = useRef<HTMLDivElement>(null);
    const { likedSongsMap } = usePlayer();

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
                setIsSearchOpen(false);
            }
        };

        if (isSearchOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSearchOpen]);

    useEffect(() => {
        if (isSearchOpen && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [isSearchOpen]);

    const handlePlayClick = async (e: React.MouseEvent<HTMLButtonElement>, song: Song) => {
        e.preventDefault();
        e.stopPropagation();

        if (currentSong?.id === song.id) {
            contextTogglePlay();
        } else {
            await contextSetCurrentSong(song);
            setCurrentSongIndex(instrumentals.findIndex((s) => s.id === song.id));
        }
        setIsVideoPlayerOpen(true); // On utilise la prop passée par AppContent au lieu de l'état local
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const toggleSearch = () => {
        setIsSearchOpen(prev => !prev);
    };

    const getLikedSongs = () => {
        return instrumentals.filter(song => user?.likedSongs?.includes(song.id!));
    };

    const filteredPlaylists = [...playlists].filter(playlist =>
        playlist.userId === user?.id &&
        playlist.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredInstrumentals = instrumentals.filter((song: Song) =>
        song.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        song.artist.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleAddToPlaylist = (song: Song) => {
        setSelectedSong(song);
        setIsAddToPlaylistModalOpen(true);
    };

    const handlePlaylistSelect = async (playlistId: string) => {
        if (selectedSong && selectedSong.id) {
            await addSongToPlaylist(playlistId, selectedSong.id);
            setIsAddToPlaylistModalOpen(false);
            setSelectedSong(null);
        }
    };

    const handleImageClick = (song: Song) => {
        if (song.videoUrl) {
            if (currentSong?.id !== song.id) {
                contextSetCurrentSong(song);
            }
            setIsVideoPlayerOpen(true); // On utilise la prop passée par AppContent au lieu de l'état local
        }
    };

    const handleAddPlaylist = async (name: string): Promise<void> => {
        try {
            const newPlaylist = await createPlaylist(name);
            setIsAddPlaylistModalOpen(false);
        } catch (error) {
            console.error("Erreur lors de la création de la playlist:", error);
        }
    };

    return (
        <div className="flex flex-col min-h-screen bg-gradient-to-b from-gray-900 to-black text-white p-4">
            {/* ... header et navigation ... */}
            <div className="flex items-center justify-between mb-6">
                <div className="flex items-center">
                    <button onClick={() => navigate('/')} className="mr-4">
                        <ChevronLeft size={24} />
                    </button>
                    <h1 className="text-3xl font-bold">Instrumentales</h1>
                </div>
                <div ref={searchRef} className="relative">
                    <SearchIcon
                        className="text-white cursor-pointer"
                        size={24}
                        onClick={toggleSearch}
                    />
                </div>
            </div>

            {isSearchOpen && (
                <div className="w-full mt-2 mb-6">
                    <input
                        ref={searchInputRef}
                        type="text"
                        placeholder="Rechercher..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="w-full pl-10 pr-4 py-2 bg-gray-800 text-white rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
            )}

            <section className="mb-8">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold">Mes Playlists</h2>
                </div>
                <div className="flex space-x-4 overflow-x-auto scrollbar-hide">
                    {/* Ajouter le cadre avec un "+" avant la playlist "Titres Likés" */}
                    <div
                        className="flex-shrink-0 h-40 w-40 cursor-pointer bg-gray-800 rounded-lg shadow-lg flex justify-center items-center"
                        onClick={() => setIsAddPlaylistModalOpen(true)}
                    >
                        <button className="text-white rounded-full p-2">
                            <Plus size={24} />
                        </button>
                    </div>
                    {/* Playlist "Titres Likés" */}
                    <div className="relative flex-shrink-0 w-40 cursor-pointer group">
                        <div className="w-full">
                            <div
                                onClick={(e) => {
                                    if (!(e.target as HTMLElement).closest('.playlist-actions')) {
                                        navigate(`/playlist/likes`);
                                    }
                                }}
                            >
                                <img
                                    src="/likes.png"
                                    alt="Titre Likés"
                                    className="w-40 h-40 object-cover rounded-lg shadow-lg"
                                />
                            </div>
                            <div className="mt-2 flex items-center justify-between">
                                <p className="font-semibold text-sm truncate flex-grow">Titres Likés</p>
                                <div
                                    className="flex-shrink-0 ml-2 playlist-actions"
                                    onClick={e => e.stopPropagation()}
                                >
                                    <PlaylistActions
                                        playlist={{
                                            id: 'likes',
                                            name: 'Titres Likés',
                                            imageUrl: '/likes.png',
                                            createdAt: Timestamp.now(),
                                            userId: user?.id || '',
                                            songs: [],
                                            isSystem: true
                                        }}
                                        onRename={updatePlaylistName}
                                        onChangeImage={updatePlaylistImage}
                                        onDelete={deletePlaylist}
                                        isLikedPlaylist={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Autres playlists */}
                    {filteredPlaylists.map((playlist) => (
                        <div
                            key={playlist.id}
                            className="relative flex-shrink-0 w-40 cursor-pointer group"
                        >
                            <div className="w-full">
                                <div
                                    onClick={(e) => {
                                        if (!(e.target as HTMLElement).closest('.playlist-actions')) {
                                            navigate(`/playlist/${playlist.id}`);
                                        }
                                    }}
                                >
                                    <img
                                        src={playlist.imageUrl || "/placeholder_playlist.png"}
                                        alt={playlist.name}
                                        className="w-40 h-40 object-cover rounded-lg shadow-lg"
                                    />
                                </div>
                                <div className="mt-2 flex items-center justify-between">
                                    <p className="font-semibold text-sm truncate flex-grow">{playlist.name}</p>
                                    <div
                                        className="flex-shrink-0 ml-2 playlist-actions"
                                        onClick={e => e.stopPropagation()}
                                    >
                                        <PlaylistActions
                                            playlist={playlist}
                                            onRename={updatePlaylistName}
                                            onChangeImage={updatePlaylistImage}
                                            onDelete={deletePlaylist}
                                            isLikedPlaylist={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Section Instrumentales */}
            <section className="mb-8">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold">Les instrumentales</h2>
                </div>
                <div
                    ref={scrollRef}
                    className="flex space-x-4 overflow-x-auto scrollbar-hide"
                    style={{ scrollBehavior: 'smooth' }}
                >
                    {filteredInstrumentals.map((song) => (
                        <div key={song.id} className="flex-shrink-0">
                            <div className="relative">
                                <img
                                    src={song.thumbnailUrl || "/placeholder-image.png"}
                                    alt={song.name}
                                    className={`w-full h-40 object-cover rounded-lg cursor-pointer ${currentSong?.id === song.id && isPlaying ? 'spinning' : ''
                                        }`}
                                    onClick={() => handleImageClick(song)}
                                />
                                <button
                                    type="button"
                                    onClick={(e) => handlePlayClick(e, song)}
                                    className="absolute bottom-2 right-2 bg-green-500 text-white rounded-full p-2 hover:bg-green-600"
                                >
                                    {currentSong?.id === song.id && isPlaying ? (
                                        <FaPause size={15} />
                                    ) : (
                                        <FaPlay size={15} />
                                    )}
                                </button>
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <div className="flex space-x-2">
                                    <button
                                        type="button"
                                        onClick={() => handleAddToPlaylist(song)}
                                        className="text-white hover:text-green-500"
                                    >
                                        <Plus size={20} />
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => song.id && handleLike(song.id)}
                                        className={`hover:text-red-500 transition-colors ${likedSongs.includes(song.id || '') ? 'text-red-500' : 'text-white'
                                            }`}
                                    >
                                        <Heart
                                            size={20}
                                            fill={likedSongs.includes(song.id || '') ? "currentColor" : "none"}
                                        />
                                    </button>
                                </div>
                            </div>
                            <p className="mt-1 font-semibold text-sm truncate">{song.name}</p>
                            <p className="text-xs text-gray-400 truncate">{song.artist}</p>
                        </div>
                    ))}
                </div>
            </section>

            {/* Modals */}
            {isAddToPlaylistModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-gray-800 p-6 rounded-lg w-80">
                        <h3 className="text-xl font-bold mb-4">Ajouter à une playlist</h3>
                        <div className="space-y-2">
                            <button
                                type="button"
                                onClick={() => setIsAddPlaylistModalOpen(true)}
                                className="w-full text-left py-2 px-4 hover:bg-gray-700 rounded mb-2"
                            >
                                Créer une nouvelle playlist
                            </button>
                            {playlists.filter(playlist => playlist.userId === user?.id).map((playlist) => (
                                <button
                                    type="button"
                                    key={playlist.id}
                                    onClick={() => handlePlaylistSelect(playlist.id!)}
                                    className="w-full text-left py-2 px-4 hover:bg-gray-700 rounded"
                                >
                                    {playlist.name}
                                </button>
                            ))}
                        </div>
                        <button
                            type="button"
                            onClick={() => setIsAddToPlaylistModalOpen(false)}
                            className="mt-4 w-full bg-red-500 text-white py-2 px-4 rounded"
                        >
                            Annuler
                        </button>
                    </div>
                </div>
            )}

            {isAddPlaylistModalOpen && (
                <AddPlaylistModal
                    isOpen={isAddPlaylistModalOpen}
                    onClose={() => setIsAddPlaylistModalOpen(false)}
                    onAddPlaylist={handleAddPlaylist} // Utiliser le wrapper à la place
                />
            )}

            {isAddInstrumentalModalOpen && (
                <AddInstrumentalModal
                    isOpen={isAddInstrumentalModalOpen}
                    onClose={() => setIsAddInstrumentalModalOpen(false)}
                    onAddInstrumental={onAddInstrumental}
                    albums={[]} // Ajouter la propriété albums manquante
                />
            )}
        </div>
    );
};

export default Collection;