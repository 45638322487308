// src/components/AddPlaylistModal.tsx
import React, { useState } from 'react';
import { X, Check } from "lucide-react";
import DOMPurify from 'dompurify';
import { AddPlaylistModalProps } from '../types';

const AddPlaylistModal: React.FC<AddPlaylistModalProps> = ({
    isOpen,
    onClose,
    onAddPlaylist
}) => {
    const [name, setName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (name.trim()) {
            setIsLoading(true);
            setError(null);
            try {
                await onAddPlaylist(name);
                setName('');
                onClose();
            } catch (error) {
                console.error("Erreur lors de la création de la playlist:", error);
                setError("Une erreur est survenue lors de la création de la playlist");
            } finally {
                setIsLoading(false);
            }
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-gray-800 p-6 rounded-lg w-full max-w-md relative">
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-gray-400 hover:text-white"
                >
                    <X size={24} />
                </button>

                <h2 className="text-xl font-bold mb-6 text-white">Créer une playlist</h2>

                {error && (
                    <div className="mb-4 text-red-500 text-sm">
                        {error}
                    </div>
                )}

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label
                            htmlFor="playlist-name"
                            className="block text-sm font-medium text-gray-300 mb-2"
                        >
                            Nom de la playlist
                        </label>
                        <input
                            type="text"
                            id="playlist-name"
                            value={DOMPurify.sanitize(name)}
                            onChange={(e) => setName(DOMPurify.sanitize(e.target.value))}
                            placeholder="Ma nouvelle playlist"
                            className="w-full p-2 bg-gray-700 text-white rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                            maxLength={25}
                            required
                        />
                    </div>

                    <div className="flex justify-end space-x-2 pt-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-700 text-white rounded hover:bg-gray-600 transition-colors"
                        >
                            Annuler
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors disabled:bg-gray-500"
                            disabled={isLoading || !name.trim()}
                        >
                            {isLoading ? (
                                <span className="flex items-center">
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Création...
                                </span>
                            ) : (
                                <span className="flex items-center">
                                    <Check className="mr-2" size={20} />
                                    Créer
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddPlaylistModal;