// App.tsx
import React, { useState, useEffect, useCallback } from "react";
import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs, arrayUnion, getDoc, Timestamp, setDoc, query, where, arrayRemove } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { User as FirebaseUser, getAuth, updateProfile, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, EmailAuthProvider, reauthenticateWithCredential, updatePassword, updateEmail, verifyBeforeUpdateEmail } from "firebase/auth";
import DOMPurify from 'dompurify';
import { app, db } from './components/firebaseConfig';
import { usePlayer, PlayerProvider } from './contexts/PlayerContext';
import AppContent from './components/AppContent';
import AuthScreen from './components/AuthScreen';
import { Song, User, Playlist, Album, UserData } from './types';

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [songs, setSongs] = useState<Song[]>([]);
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  const [albums, setAlbums] = useState<Album[]>([]);
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isCreatePlaylistModalOpen, setIsCreatePlaylistModalOpen] = useState(false);
  const [isEditPlaylistModalOpen, setIsEditPlaylistModalOpen] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isVideoPlayerOpen, setIsVideoPlayerOpen] = useState(false);
  const [currentEditPlaylist, setCurrentEditPlaylist] = useState<Playlist | null>(null);

  const auth = getAuth(app);

  const addSongToPlaylist = async (playlistId: string, songId: string): Promise<void> => {
    if (!user) return;

    try {
      const playlistRef = doc(db, "playlists", playlistId);
      await updateDoc(playlistRef, {
        songs: arrayUnion(songId)
      });

      setPlaylists(prev => prev.map(playlist =>
        playlist.id === playlistId
          ? { ...playlist, songs: [...playlist.songs, songId] }
          : playlist
      ));
    } catch (error) {
      console.error("Erreur lors de l'ajout de la chanson:", error);
      throw error;
    }
  };

  const handleCreatePlaylist = async (name: string): Promise<Playlist> => {
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        throw new Error("Utilisateur non connecté");
      }

      // Création des données de la playlist
      const newPlaylistData: Omit<Playlist, 'id'> = {
        name: name.trim(),
        songs: [],
        imageUrl: "/placeholder_playlist.png",
        userId: currentUser.uid,
        createdAt: Timestamp.now(),
        isSystem: false
      };

      // Ajout dans Firestore
      const docRef = await addDoc(collection(db, "playlists"), newPlaylistData);

      // Création de l'objet playlist complet avec l'ID
      const newPlaylist: Playlist = {
        id: docRef.id,
        ...newPlaylistData
      };

      // Mise à jour de l'état local des playlists
      setPlaylists(prevPlaylists => [...prevPlaylists, newPlaylist]);

      return newPlaylist;
    } catch (error) {
      console.error('Erreur lors de la création de la playlist:', error);
      throw error;
    }
  };

  const removeSongFromPlaylist = async (playlistId: string, songId: string): Promise<void> => {
    if (!user) return;

    try {
      const playlistRef = doc(db, "playlists", playlistId);
      await updateDoc(playlistRef, {
        songs: arrayRemove(songId)
      });

      setPlaylists(prev => prev.map(playlist =>
        playlist.id === playlistId
          ? { ...playlist, songs: playlist.songs.filter(id => id !== songId) }
          : playlist
      ));
    } catch (error) {
      console.error("Erreur lors de la suppression de la chanson:", error);
      throw error;
    }
  };

  const deletePlaylist = async (playlistId: string): Promise<void> => {
    if (!user) return;

    try {
      await deleteDoc(doc(db, "playlists", playlistId));
      setPlaylists(prev => prev.filter(playlist => playlist.id !== playlistId));
    } catch (error) {
      console.error("Erreur lors de la suppression de la playlist:", error);
      throw error;
    }
  };

  const updatePlaylistImage = async (playlistId: string, imageUrl: string): Promise<void> => {
    if (!user) return;

    try {
      const playlistRef = doc(db, "playlists", playlistId);
      await updateDoc(playlistRef, { imageUrl });
      setPlaylists(prev => prev.map(playlist =>
        playlist.id === playlistId
          ? { ...playlist, imageUrl }
          : playlist
      ));
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'image:", error);
      throw error;
    }
  };

  const updatePlaylistName = async (playlistId: string, name: string): Promise<void> => {
    if (!user) return;

    try {
      const playlistRef = doc(db, "playlists", playlistId);
      await updateDoc(playlistRef, { name });
      setPlaylists(prev => prev.map(playlist =>
        playlist.id === playlistId
          ? { ...playlist, name }
          : playlist
      ));
    } catch (error) {
      console.error("Erreur lors de la mise à jour du nom:", error);
      throw error;
    }
  };

  const changePassword = async (oldPassword: string, newPassword: string): Promise<void> => {
    if (!user || !auth.currentUser) throw new Error("Non authentifié");

    try {
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email!,
        oldPassword
      );
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updatePassword(auth.currentUser, newPassword);
    } catch (error) {
      console.error("Erreur lors du changement de mot de passe:", error);
      throw error;
    }
  };

  const changeEmail = async (newEmail: string, currentPassword: string): Promise<void> => {
    if (!auth.currentUser) throw new Error("Non authentifié");

    try {
      // Créer les credentials avec l'email actuel et le mot de passe fourni
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email!,
        currentPassword
      );

      // Ré-authentifier l'utilisateur
      await reauthenticateWithCredential(auth.currentUser, credential);

      // Une fois ré-authentifié, on peut envoyer l'email de vérification
      await verifyBeforeUpdateEmail(auth.currentUser, newEmail);

    } catch (error: any) {
      if (error.code === 'auth/wrong-password') {
        throw new Error("Mot de passe incorrect");
      } else if (error.code === 'auth/too-many-requests') {
        throw new Error("Trop de tentatives, veuillez réessayer plus tard");
      } else {
        console.error("Erreur lors du changement d'email:", error);
        throw new Error("Une erreur est survenue lors du changement d'email");
      }
    }
  };

  const changeName = async (newName: string): Promise<void> => {
    if (!auth.currentUser) {
      throw new Error("Utilisateur Non authentifié");
    }

    try {
      const userRef = doc(db, "users", auth.currentUser.uid);

      // Mettre à jour dans Firebase Auth
      await updateProfile(auth.currentUser, {
        displayName: newName
      });

      // Mettre à jour dans Firestore
      const userData: Partial<UserData> = {
        name: newName,
        displayName: newName,
        updatedAt: Timestamp.now()
      };

      await updateDoc(userRef, userData);

      // Mettre à jour l'état local avec le nouveau timestamp
      setUser(currentUser => {
        if (!currentUser) return null;
        return {
          ...currentUser,
          name: newName,
          displayName: newName,
          updatedAt: Timestamp.now()
        };
      });

      // Vérification de la mise à jour
      const updatedDoc = await getDoc(userRef);
      if (updatedDoc.exists()) {
        const updatedUserData = updatedDoc.data() as UserData;
      }

    } catch (error) {
      console.error("Erreur lors du changement de nom:", error);
      throw error;
    }
  };


  useEffect(() => {
    const fetchAlbums = async () => {
      try {
        const albumsSnapshot = await getDocs(collection(db, "albums"));
        const albumsData = albumsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || new Date()
        })) as Album[];
        setAlbums(albumsData);
      } catch (error) {
        console.error("Erreur lors du chargement des albums:", error);
      }
    };

    fetchAlbums();
  }, []);

  const addInstrumental = async (
    name: string,
    artist: string,
    audioFile: File,
    imageFile: File | null,
    duration: number,
    videoFile: File | null,
    tag?: string
  ): Promise<void> => {
    try {
      const storage = getStorage();

      // Upload audio
      const audioRef = ref(storage, `instrumentals/${audioFile.name}`);
      await uploadBytes(audioRef, audioFile);
      const url = await getDownloadURL(audioRef);

      // Upload thumbnail
      let thumbnailUrl = "";
      if (imageFile) {
        const imageRef = ref(storage, `thumbnails/${imageFile.name}`);
        await uploadBytes(imageRef, imageFile);
        thumbnailUrl = await getDownloadURL(imageRef);
      }

      // Upload video
      let videoUrl = "";
      if (videoFile) {
        const videoRef = ref(storage, `videos/${videoFile.name}`);
        await uploadBytes(videoRef, videoFile);
        videoUrl = await getDownloadURL(videoRef);
      }

      const instrumentalData = {
        name,
        artist,
        url,
        duration,
        thumbnailUrl,
        videoUrl,
        tag,
        isLiked: false,
        likedBy: [],
        createdAt: Timestamp.now()
      };

      const docRef = await addDoc(collection(db, "songs"), instrumentalData);
      setSongs(prev => [...prev, { id: docRef.id, ...instrumentalData }]);

      if (tag) {
        const albumQuery = query(collection(db, "albums"), where("name", "==", tag));
        const albumSnapshot = await getDocs(albumQuery);

        if (!albumSnapshot.empty) {
          const albumDoc = albumSnapshot.docs[0];
          await updateDoc(doc(db, "albums", albumDoc.id), {
            instrumentals: arrayUnion(docRef.id)
          });

          setAlbums(prevAlbums => prevAlbums.map(album => {
            if (album.id === albumDoc.id) {
              return {
                ...album,
                instrumentals: [...album.instrumentals, docRef.id]
              };
            }
            return album;
          }));
        }
      }
    } catch (error) {
      console.error("Error adding instrumental:", error);
      throw error;
    }
  };

  const toggleLikeSong = useCallback(async (songId: string) => {
    if (!user?.id) return;

    const userRef = doc(db, "users", user.id);
    const likedPlaylistId = `likes_${user.id}`;
    const likedPlaylistRef = doc(db, "playlists", likedPlaylistId);

    try {
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data() as UserData;
        const likedSongs = userData.likedSongs || [];
        const isLiked = likedSongs.includes(songId);

        // Mettre à jour les chansons likées
        await Promise.all([
          updateDoc(userRef, {
            likedSongs: isLiked ? arrayRemove(songId) : arrayUnion(songId)
          }),
          updateDoc(likedPlaylistRef, {
            songs: isLiked ? arrayRemove(songId) : arrayUnion(songId)
          })
        ]);

        // Mettre à jour l'état de l'utilisateur immédiatement
        setUser(prevUser => {
          if (!prevUser) return null;
          const newLikedSongs = isLiked
            ? prevUser.likedSongs.filter(id => id !== songId)
            : [...prevUser.likedSongs, songId];
          return {
            ...prevUser,
            likedSongs: newLikedSongs
          };
        });

        // Mettre à jour l'état des chansons si nécessaire
        setSongs(prevSongs => prevSongs.map(song => {
          if (song.id === songId) {
            return {
              ...song,
              isLiked: !isLiked
            };
          }
          return song;
        }));
      }
    } catch (error) {
      console.error("Erreur lors du toggle like:", error);
    }
  }, [user, db]);

  useEffect(() => {
    if (user) {
      const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
        if (firebaseUser && firebaseUser.email !== user.email) {
          // L'email a été vérifié et changé
          try {
            const userRef = doc(db, "users", firebaseUser.uid);
            await updateDoc(userRef, {
              email: firebaseUser.email,
              updatedAt: Timestamp.now()
            });

            setUser(prevUser => prevUser ? {
              ...prevUser,
              email: firebaseUser.email || "",
              updatedAt: Timestamp.now()
            } : null);

          } catch (error) {
            console.error("Erreur lors de la mise à jour de l'email:", error);
          }
        }
      });

      return () => unsubscribe();
    }
  }, [user]);

  const validatePassword = (password: string): boolean => {
    const hasMinLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>\-]/.test(password);
    return hasMinLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
  };

  const signUp = async (name: string, email: string, password: string): Promise<string | null> => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const { user: firebaseUser } = userCredential;

      const userData: UserData = {
        name,
        email,
        role: 'user',
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        likedSongs: [],
        displayName: name,
        photoURL: null,
      };

      await Promise.all([
        setDoc(doc(db, "users", firebaseUser.uid), userData)
      ]);

      setUser({
        ...firebaseUser,
        ...userData,
        id: firebaseUser.uid,
        emailVerified: firebaseUser.emailVerified
      });

      return null;
    } catch (error) {
      console.error("Erreur d'inscription:", error);
      return "Erreur lors de l'inscription";
    }
  };

  const login = async (email: string, password: string): Promise<string | null> => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const userDoc = await getDoc(doc(db, "users", user.uid));
      const userData = userDoc.data();

      setUser({
        ...user,
        id: user.uid,
        name: userData?.name || user.displayName || "",
        email: user.email || "",
        role: userData?.role || "user",
        likedSongs: userData?.likedSongs || [],
        createdAt: userData?.createdAt || Timestamp.now(),
        emailVerified: user.emailVerified
      });

      return null;
    } catch (error) {
      console.error("Erreur de connexion:", error);
      if (error instanceof Error && 'code' in error) {
        const firebaseError = error as { code: string };
        if (firebaseError.code === "auth/invalid-credential") {
          return "Email ou mot de passe incorrect";
        }
        return firebaseError.code;
      }
      return "Erreur inconnue";
    }
  };

  const logout = async () => {
    try {
      await auth.signOut();
      setUser(null);
    } catch (error) {
      console.error("Erreur de déconnexion:", error);
    }
  };

  // Dans le useEffect de chargement initial
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const userDoc = await getDoc(doc(db, "users", firebaseUser.uid));
          const userData = userDoc.data() as UserData;

          setUser({
            ...firebaseUser,
            id: firebaseUser.uid,
            uid: firebaseUser.uid,
            name: userData?.name || firebaseUser.displayName || "",
            email: firebaseUser.email || "",
            role: userData?.role || "user",
            likedSongs: userData?.likedSongs || [],
            createdAt: userData?.createdAt || Timestamp.now(),
            emailVerified: firebaseUser.emailVerified,
            displayName: userData?.name || firebaseUser.displayName
          });

        } catch (error) {
          console.error("Error loading user data:", error);
          setUser(null);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Initial data loading
  useEffect(() => {
    const fetchData = async () => {
      const [playlistsSnapshot, songsSnapshot] = await Promise.all([
        getDocs(collection(db, "playlists")),
        getDocs(collection(db, "songs"))
      ]);

      const playlistsData = playlistsSnapshot.docs.map(
        doc => ({ id: doc.id, ...doc.data() }) as Playlist
      );
      setPlaylists(playlistsData);

      const songsData = songsSnapshot.docs.map(
        doc => ({ id: doc.id, ...doc.data() }) as Song
      );
      setSongs(songsData);
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Chargement...</div>;
  }

  return (
    <PlayerProvider songs={songs} onToggleLike={toggleLikeSong} user={user}>
      <div className="bg-black text-white min-h-screen">
        {!user ? (
          <AuthScreen
            onLogin={login}
            onSignUp={signUp}
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
        ) : (
          <AppContent
            setPlaylists={setPlaylists}
            user={user}
            songs={songs}
            playlists={playlists}
            albums={albums}
            isCreatePlaylistModalOpen={isCreatePlaylistModalOpen}
            isEditPlaylistModalOpen={isEditPlaylistModalOpen}
            isAuthModalOpen={isAuthModalOpen}
            isVideoPlayerOpen={isVideoPlayerOpen}
            currentEditPlaylist={currentEditPlaylist}
            showOldPassword={showOldPassword}
            showNewPassword={showNewPassword}
            showConfirmPassword={showConfirmPassword}
            setShowOldPassword={setShowOldPassword}
            setShowNewPassword={setShowNewPassword}
            setShowConfirmPassword={setShowConfirmPassword}
            setIsCreatePlaylistModalOpen={setIsCreatePlaylistModalOpen}
            setIsEditPlaylistModalOpen={setIsEditPlaylistModalOpen}
            setIsAuthModalOpen={setIsAuthModalOpen}
            setIsVideoPlayerOpen={setIsVideoPlayerOpen}
            setCurrentEditPlaylist={setCurrentEditPlaylist}
            addInstrumental={addInstrumental}
            toggleLikeSong={toggleLikeSong}
            logout={logout}
            addSongToPlaylist={addSongToPlaylist}
            removeSongFromPlaylist={removeSongFromPlaylist}
            deletePlaylist={deletePlaylist}
            updatePlaylistImage={updatePlaylistImage}
            updatePlaylistName={updatePlaylistName}
            changePassword={changePassword}
            changeEmail={changeEmail}
            changeName={changeName}
            createPlaylist={handleCreatePlaylist}
          />
        )}
      </div>
    </PlayerProvider>
  );
};

export default App;