// src/components/AuthScreen.tsx

import React, { useState } from 'react';
import { Info, Eye, EyeOff } from 'lucide-react';
import DOMPurify from 'dompurify';
import { AuthScreenProps } from '../types';

const AuthScreen: React.FC<AuthScreenProps> = ({
    onLogin,
    onSignUp,
    showPassword,
    setShowPassword
}) => {
    const [isSignUp, setIsSignUp] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [showPasswordRules, setShowPasswordRules] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);
        let result: string | null;

        if (isSignUp) {
            if (password !== confirmPassword) {
                setError("Les mots de passe ne correspondent pas.");
                return;
            }
            result = await onSignUp(name, email, password);
        } else {
            result = await onLogin(email, password);
        }

        if (result) {
            setError(result);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-900">
            <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-96">
                <div className="flex flex-col items-center mb-6">
                    <img src="logo.png" alt="Logo" className="w-10 h-10 mb-4" />
                    <h2 className="text-2xl font-bold text-white text-center">
                        {isSignUp ? "Inscription" : "Connexion"}
                    </h2>
                </div>

                {error && <p className="text-red-500 mb-4">{error}</p>}

                <form onSubmit={handleSubmit}>
                    {isSignUp && (
                        <input
                            type="text"
                            placeholder="Nom d'utilisateur (max 12 caractères)"
                            value={DOMPurify.sanitize(name)}
                            onChange={(e) => setName(DOMPurify.sanitize(e.target.value).slice(0, 12))}
                            className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
                            required
                        />
                    )}

                    <input
                        type="email"
                        placeholder="Email"
                        value={DOMPurify.sanitize(email)}
                        onChange={(e) => setEmail(DOMPurify.sanitize(e.target.value))}
                        className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
                        required
                    />

                    <div className="relative mb-4">
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Mot de passe"
                            value={DOMPurify.sanitize(password)}
                            onChange={(e) => setPassword(DOMPurify.sanitize(e.target.value))}
                            className="w-full p-2 bg-gray-700 text-white rounded"
                            required
                        />
                        {isSignUp && (
                            <button
                                type="button"
                                onClick={() => setShowPasswordRules(!showPasswordRules)}
                                className="absolute right-2 text-gray-400"
                                style={{ top: "0.60rem" }}
                            >
                                <Info size={20} />
                            </button>
                        )}
                    </div>

                    {isSignUp && (
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Confirmer le mot de passe"
                            value={DOMPurify.sanitize(confirmPassword)}
                            onChange={(e) => setConfirmPassword(DOMPurify.sanitize(e.target.value))}
                            className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
                            required
                        />
                    )}

                    {isSignUp && showPasswordRules && (
                        <div className="text-sm text-gray-400 mb-4">
                            <p>Le mot de passe doit contenir au minimum :</p>
                            <div className="flex justify-around">
                                <ul className="list-disc list-inside">
                                    <li>8 caractères</li>
                                    <li>1 majuscule</li>
                                    <li>1 minuscule</li>
                                </ul>
                                <ul className="list-disc list-inside">
                                    <li>1 chiffre</li>
                                    <li>1 caractère spécial</li>
                                </ul>
                            </div>
                        </div>
                    )}

                    <button
                        type="submit"
                        className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
                    >
                        {isSignUp ? "S'inscrire" : "Se connecter"}
                    </button>
                </form>

                <button
                    className="w-full text-center mt-4 text-blue-400 hover:text-blue-300"
                    onClick={() => {
                        setIsSignUp(!isSignUp);
                        setError(null);
                    }}
                >
                    {isSignUp ? "Déjà un compte ? Se connecter" : "Première fois ici ? S'inscrire"}
                </button>
            </div>
        </div>
    );
};

export default AuthScreen;