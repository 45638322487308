import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MoreVertical } from "lucide-react";
import PlaylistHeader from './PlaylistHeader';
import { Song, Playlist, PlaylistViewProps } from '../types';
import { usePlayer } from '../contexts/PlayerContext';
import { Timestamp, doc, updateDoc, getFirestore, arrayRemove, arrayUnion } from 'firebase/firestore';
import { syncVideoWithAudioState } from '../utils/playerUtils';

const PlaylistView: React.FC<PlaylistViewProps> = ({
    user,
    playlists,
    songs,
    currentSong,
    isPlaying: globalIsPlaying,
    togglePlay: globalTogglePlay,
    setCurrentSong,
    toggleLikeSong,
    removeSongFromPlaylist,
    openEditPlaylistModal,
    deletePlaylist,
    addSongToPlaylist,
    updatePlaylistImage,
    handlePlayClick,
    updatePlaylistName,
    setIsVideoPlayerOpen
}) => {
    const [selectedSongId, setSelectedSongId] = useState<string | null>(null);
    const optionsRef = useRef<HTMLDivElement>(null);
    const [showOptions, setShowOptions] = useState(false);
    const [isShuffleEnabled, setIsShuffleEnabled] = useState(false);
    const [playlistQueue, setPlaylistQueue] = useState<Song[]>([]);
    const [currentQueueIndex, setCurrentQueueIndex] = useState(-1);
    const [isPlaylistPlaying, setIsPlaylistPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const { audioRef: playerAudioRef } = usePlayer();
    const [isProcessing, setIsProcessing] = useState(false);
    const db = getFirestore();

    const [openMenuId, setOpenMenuId] = useState<string | null>(null);
    const optionsRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

    const isLikesPlaylist = id === 'likes';

    // Effet pour gérer le clic en dehors des menus
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const clickedElement = event.target as Node;
            // Vérifier si le clic est en dehors de tous les menus d'options
            const isOutsideAllMenus = Object.values(optionsRefs.current).every(
                ref => ref && !ref.contains(clickedElement)
            );

            if (isOutsideAllMenus) {
                setOpenMenuId(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleOptionsClick = (e: React.MouseEvent, songId: string) => {
        e.stopPropagation();
        // Toggle le menu pour cette chanson spécifique
        setOpenMenuId(openMenuId === songId ? null : songId);
    };

    // Gestionnaire pour le like/unlike
    const handleLike = async (e: React.MouseEvent, songId: string) => {
        e.stopPropagation();
        await toggleLikeSong(songId); // Utilise la fonction passée en props
        setSelectedSongId(null);
    };

    // Gestionnaire pour retirer de la playlist
    const handleRemoveFromPlaylist = async (e: React.MouseEvent, songId: string) => {
        e.stopPropagation();
        if (!playlist?.id) return;

        await removeSongFromPlaylist(playlist.id, songId); // Utilise la fonction passée en props

        // Si la chanson en cours est celle qu'on retire et qu'elle est en train de jouer
        if (currentSong?.id === songId && isPlaylistPlaying) {
            if (globalIsPlaying) {
                globalTogglePlay(); // Arrêter la lecture
            }
            setIsPlaylistPlaying(false);
        }

        // Mise à jour de la queue de lecture si nécessaire
        if (isPlaylistPlaying) {
            const newQueue = playlistQueue.filter(song => song.id !== songId);
            setPlaylistQueue(newQueue);
            if (currentQueueIndex >= newQueue.length) {
                setCurrentQueueIndex(newQueue.length - 1);
            }
        }

        setSelectedSongId(null);
    };

    // Gestionnaire pour le bouton play/pause principal modifié
    const handleMainPlayPause = async () => {
        if (!isPlaylistPlaying) {
            // Si on démarre une nouvelle lecture
            if (currentQueueIndex === -1) {
                const queue = createPlayQueue(isShuffleEnabled);
                setPlaylistQueue(queue);
                setCurrentQueueIndex(0);
                await setCurrentSong(queue[0]);
                setIsPlaylistPlaying(true);
                if (!globalIsPlaying) {
                    globalTogglePlay();
                }
            } else {
                // Reprendre la lecture depuis la position actuelle
                setIsPlaylistPlaying(true);
                if (!globalIsPlaying) {
                    globalTogglePlay();
                }
            }
        } else {
            // Mettre en pause
            setIsPlaylistPlaying(false);
            globalTogglePlay();
        }
    };


    // Obtenir la playlist et ses chansons
    const playlist = isLikesPlaylist
        ? {
            id: 'likes',
            name: 'Likes',
            imageUrl: '/likes.png',
            songs: songs.filter(song => user?.likedSongs?.includes(song.id!)).map(song => song.id!),
            userId: user?.id ?? '',
            createdAt: Timestamp.now(),
            isSystem: true
        }
        : playlists.find((p) => p.id === id);

    const playlistSongs = isLikesPlaylist
        ? songs.filter(song => user?.likedSongs?.includes(song.id!))
        : songs.filter((song) => playlist?.songs.includes(song.id || ""));

    // Vérifier si la chanson actuelle fait partie de la playlist
    const isCurrentSongInPlaylist = useCallback(() => {
        return playlistSongs.some(song => song.id === currentSong?.id);
    }, [playlistSongs, currentSong]);

    // Créer une queue de lecture
    const createPlayQueue = useCallback((shuffle: boolean = false) => {
        let queue = [...playlistSongs];
        if (shuffle) {
            for (let i = queue.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [queue[i], queue[j]] = [queue[j], queue[i]];
            }
        }
        setPlaylistQueue(queue);
        return queue;
    }, [playlistSongs]);

    // Gestionnaire de fin de chanson mis à jour
    const handleSongEnd = useCallback(async () => {
        if (!isPlaylistPlaying) return;

        if (currentQueueIndex < playlistQueue.length - 1) {
            // Il y a encore des chansons dans la queue
            const nextSong = playlistQueue[currentQueueIndex + 1];
            await setCurrentSong(nextSong);
            setCurrentQueueIndex(prev => prev + 1);
        } else {
            // Fin de la playlist
            setIsPlaylistPlaying(false);
            setCurrentQueueIndex(-1);
            setPlaylistQueue([]);
            if (globalIsPlaying) {
                globalTogglePlay(); // Arrêter la lecture
            }
        }
    }, [currentQueueIndex, playlistQueue, setCurrentSong, isPlaylistPlaying, globalTogglePlay, globalIsPlaying]);

    // Gestionnaire pour le bouton shuffle
    const handleShuffleClick = () => {
        setIsShuffleEnabled(!isShuffleEnabled);
        if (isPlaylistPlaying) {
            const currentSongId = currentSong?.id;
            const newQueue = createPlayQueue(!isShuffleEnabled);
            const newIndex = newQueue.findIndex(song => song.id === currentSongId);
            setCurrentQueueIndex(newIndex);
        }
    };

    // Gestion de l'audio
    useEffect(() => {
        const audio = document.querySelector('audio');
        if (audio && isPlaylistPlaying) {
            audio.addEventListener('ended', handleSongEnd);
            return () => audio.removeEventListener('ended', handleSongEnd);
        }
    }, [handleSongEnd, isPlaylistPlaying]);

    // Synchronisation vidéo/audio
    useEffect(() => {
        if (!isCurrentSongInPlaylist()) {
            setIsPlaylistPlaying(false);
        }
    }, [currentSong, isCurrentSongInPlaylist]);

    const handleSongClick = async (song: Song) => {
        if (currentSong?.id === song.id) {
            globalTogglePlay();
            syncVideoWithAudioState(!globalIsPlaying);
        } else {
            await setCurrentSong(song);
            syncVideoWithAudioState(true);
        }
    };

    const calculateTotalDuration = (songs: Song[]): number => {
        return songs.reduce((total, song) => total + (song.duration || 0), 0);
    };

    const getPlaylist = (): Playlist | undefined => {
        if (isLikesPlaylist) {
            return {
                id: 'likes',
                name: 'Likes',
                imageUrl: '/likes.png',
                songs: songs.filter(song => user?.likedSongs?.includes(song.id!)).map(song => song.id!),
                userId: user?.id ?? '',
                createdAt: Timestamp.now(),
                isSystem: true
            };
        }
        return playlists.find((p) => p.id === id);
    };

    if (!playlist) {
        return <div>Chargement de la playlist...</div>;
    }

    return (
        <div className="flex flex-col lg:flex-row min-h-screen bg-black">
            {/* Colonne gauche (PlaylistHeader) sur desktop */}
            <div className="w-full lg:w-[40%] lg:sticky lg:top-0 lg:h-screen">
                <PlaylistHeader
                    playlistName={playlist?.name || ''}
                    createdAt={playlist?.createdAt || Timestamp.now()}
                    totalSongs={playlistSongs.length}
                    totalDuration={playlistSongs.reduce((acc, song) => acc + (song.duration || 0), 0)}
                    currentSong={isCurrentSongInPlaylist() ? currentSong : null}
                    isPlaying={isPlaylistPlaying && globalIsPlaying}
                    onPlayPause={handleMainPlayPause}
                    playlistImageUrl={playlist?.imageUrl || ''}
                    onShuffleClick={handleShuffleClick}
                    isShuffleEnabled={isShuffleEnabled}
                    showPlaylistImage={!isCurrentSongInPlaylist() || !currentSong?.videoUrl}
                    currentTime={currentTime}
                />
            </div>

            {/* Colonne droite (Liste des chansons) sur desktop */}
            <div className="flex-1 lg:pl-8 lg:pr-4 lg:py-4 lg:max-h-screen lg:overflow-y-auto">
                <div className="mt-4 space-y-2 px-5 lg:px-0">
                    {playlistSongs.map((song, index) => (
                        <div
                            key={song.id}
                            className={`flex items-center justify-between p-3 hover:bg-gray-900/50 rounded-lg cursor-pointer
                            ${currentSong?.id === song.id && isPlaylistPlaying ? 'bg-gray-800/50' : ''}`}
                            onClick={() => handleSongClick(song)}
                        >
                            <div className="flex items-center space-x-4 flex-grow">
                                <span className="w-6 text-gray-400">{index + 1}</span>
                                <div>
                                    <h3 className="font-medium">{song.name}</h3>
                                    <p className="text-sm text-gray-400">{song.artist}</p>
                                </div>
                            </div>

                            <div
                                className="relative flex-shrink-0"
                                ref={el => optionsRefs.current[song.id || ''] = el}
                            >
                                <button
                                    onClick={(e) => song.id && handleOptionsClick(e, song.id)}
                                    className="p-2 hover:bg-gray-800 rounded-full"
                                >
                                    <MoreVertical size={20} />
                                </button>

                                {openMenuId === song.id && song.id && (
                                    <div className="absolute right-0 mt-1 w-48 bg-gray-800 rounded-md shadow-lg z-10">
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                song.id && handleLike(e, song.id);
                                            }}
                                            className="w-full text-left px-4 py-2 hover:bg-gray-700"
                                        >
                                            {user?.likedSongs?.includes(song.id) ? 'Ne plus aimer' : 'Aimer'}
                                        </button>
                                        {!isLikesPlaylist && (
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    song.id && handleRemoveFromPlaylist(e, song.id);
                                                }}
                                                className="w-full text-left px-4 py-2 hover:bg-gray-700 text-red-500"
                                            >
                                                Retirer de la playlist
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PlaylistView;