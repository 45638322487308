import React, { useState, useCallback, useRef, useEffect } from 'react';
import { MoreHorizontal, Pencil, Image, Trash2 } from 'lucide-react';
import { Playlist, PlaylistActionsProps } from '../types';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const PlaylistActions: React.FC<PlaylistActionsProps> = ({
    playlist,
    onRename,
    onChangeImage,
    onDelete,
    isLikedPlaylist = false
}) => {
    const [showOptions, setShowOptions] = useState(false);
    const [isRenaming, setIsRenaming] = useState(false);
    const [newName, setNewName] = useState(playlist.name);
    const [isUploading, setIsUploading] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (buttonRef.current?.contains(event.target as Node)) {
                return;
            }
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setShowOptions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleRename = async () => {
        await onRename(playlist.id!, newName);
        setIsRenaming(false);
        setShowOptions(false);
    };

    const handleImageChange = useCallback(async (event: Event) => {
        const target = event.target as HTMLInputElement;
        const file = target.files?.[0];

        if (file && playlist.id) {
            try {
                setIsUploading(true);
                const storage = getStorage();

                // Créer une référence unique pour l'image
                const imageRef = ref(storage, `playlist-images/${playlist.id}/${Date.now()}-${file.name}`);

                // Uploader l'image
                await uploadBytes(imageRef, file);

                // Obtenir l'URL de téléchargement
                const downloadUrl = await getDownloadURL(imageRef);

                // Mettre à jour l'URL de l'image de la playlist
                await onChangeImage(playlist.id, downloadUrl);
                setShowOptions(false);
            } catch (error) {
                console.error('Erreur lors du téléchargement de l\'image:', error);
                alert('Erreur lors du téléchargement de l\'image. Veuillez réessayer.');
            } finally {
                setIsUploading(false);
            }
        }
    }, [playlist.id, onChangeImage]);



    return (
        <div className="playlist-actions relative inline-block">
            <button
                ref={buttonRef}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowOptions(!showOptions);
                }}
                className="p-1 rounded-full hover:bg-gray-700/50"
            >
                <MoreHorizontal className="w-5 h-5" />
            </button>

            {showOptions && (
                <div
                    ref={menuRef}
                    className="fixed z-[100] min-w-[200px] bg-gray-800 rounded-md shadow-lg py-1 border border-gray-700"
                    style={{
                        top: buttonRef.current ? buttonRef.current.getBoundingClientRect().bottom + 5 : 0,
                        left: buttonRef.current ? buttonRef.current.getBoundingClientRect().left - 180 : 0
                    }}
                >
                    {!isLikedPlaylist && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsRenaming(true);
                                setShowOptions(false);
                            }}
                            className="w-full flex items-center px-4 py-2 text-sm text-white hover:bg-gray-700/50"
                        >
                            <Pencil className="w-4 h-4 mr-3" />
                            Changer le nom
                        </button>
                    )}

                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            if (!isUploading) {
                                const input = document.createElement('input');
                                input.type = 'file';
                                input.accept = 'image/*';
                                input.onchange = handleImageChange;
                                input.click();
                            }
                        }}
                        className="w-full flex items-center px-4 py-2 text-sm text-white hover:bg-gray-700/50 disabled:opacity-50"
                        disabled={isUploading}
                    >
                        <Image className="w-4 h-4 mr-3" />
                        {isUploading ? 'Téléchargement...' : 'Changer l\'image'}
                    </button>

                    {!isLikedPlaylist && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                if (window.confirm('Êtes-vous sûr de vouloir supprimer cette playlist ?')) {
                                    onDelete(playlist.id!);
                                }
                                setShowOptions(false);
                            }}
                            className="w-full flex items-center px-4 py-2 text-sm text-red-500 hover:bg-gray-700/50"
                        >
                            <Trash2 className="w-4 h-4 mr-3" />
                            Supprimer
                        </button>
                    )}
                </div>
            )}

            {isRenaming && (
                <div
                    className="fixed inset-0 bg-black/50 flex items-center justify-center z-[200]"
                    onClick={(e) => e.stopPropagation()}
                >
                    <div
                        className="bg-gray-800 p-6 rounded-lg w-96 shadow-xl"
                        onClick={e => e.stopPropagation()}
                    >
                        <h3 className="text-xl font-bold mb-4">Renommer la playlist</h3>
                        <input
                            type="text"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            className="w-full p-2 mb-4 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            autoFocus
                        />
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={() => {
                                    setIsRenaming(false);
                                    setShowOptions(false);
                                }}
                                className="px-4 py-2 bg-gray-700 rounded hover:bg-gray-600 transition-colors"
                            >
                                Annuler
                            </button>
                            <button
                                onClick={handleRename}
                                className="px-4 py-2 bg-blue-500 rounded hover:bg-blue-600 transition-colors"
                            >
                                Renommer
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PlaylistActions;